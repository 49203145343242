import { all, put } from 'redux-saga/effects';
import { urls, makeRequest } from '../../components/Common/ApiServices';
import * as actions from '../trialFilters/actions';

export function* getTrialFiltersSaga(action) {
  yield put(actions.trialFiltersRequest());
  const actionData = action.actionData;

  const programsData = {
    url: urls.PROGRAMS,
    method: 'GET',
    data: {},
  };
  const classesData = {
    url: urls.CLASSES,
    method: 'GET',
    data: {},
  };
  const seriesData = {
    url: urls.SERIES,
    method: 'GET',
    data: {},
  };
  const colorsData = {
    url: urls.COLORS,
    method: 'GET',
    data: {},
  };
  const locationsData = {
    url: urls.SEARCH_LOCATIONS,
    method: 'POST',
    data: {},
  };
  const breedersData = {
    url: urls.BREEDERS,
    method: 'GET',
    data: {},
  };
  const hardinessZonesData = {
    url: urls.HARDINESS_ZONES,
    method: 'POST',
    data: {},
  };
  const marketStatusesData = {
    url: urls.MARKET_STATUSES,
    method: 'GET',
    data: {},
  };

  let locations = [];

  try {
    const rawLocations = yield makeRequest(locationsData, actionData);
    locations = rawLocations.data.results.map(
      (location) => location.location.id
    );
  } catch (error) {
    yield put(actions.trialFiltersFail(error.response));
  }

  const sectionData = {
    query: {
      location: {
        id: {
          in: locations,
        },
      },
    },
  };
  const sectionsData = {
    url: urls.SEARCH_SECTIONS,
    method: 'POST',
    data: sectionData,
  };

  try {
    let [
      sections,
      programs,
      genuses,
      series,
      colors,
      breeders,
      marketStatuses,
      hardinessZones,
    ] = yield all([
      makeRequest(sectionsData, actionData),
      makeRequest(programsData, actionData),
      makeRequest(classesData, actionData),
      makeRequest(seriesData, actionData),
      makeRequest(colorsData, actionData),
      makeRequest(breedersData, actionData),
      makeRequest(marketStatusesData, actionData),
      makeRequest(hardinessZonesData, actionData),
    ]);

    sections = sections.data.results
      .map(({ section }) => ({ id: section.id, name: section.name }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    programs = programs.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    genuses = genuses.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    series = series.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    colors = colors.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    breeders = breeders.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    marketStatuses = marketStatuses.data.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    yield put(
      actions.trialFiltersSuccess(
        sections,
        programs,
        genuses,
        series,
        colors,
        breeders,
        marketStatuses,
        hardinessZones
      )
    );
  } catch (error) {
    yield put(actions.trialFiltersFail(error.response));
  }
}
