import { all, put } from 'redux-saga/effects';
import * as actions from '../newLogOptions/actions';
import { urls, makeRequest } from '../../components/Common/ApiServices';
import { getMappedTrialFields } from '../../components/Trials/utils';

export function* getNewLogOptionsSaga(action) {
  yield put(actions.newLogOptionsRequest());

  const actionData = action.actionData;

  const fieldData = {
    url: urls.SEARCH_TRIAL_FIELDS,
    method: 'POST',
    data: {
      query: {
        trial: {
          id: {
            in: [action.trialId],
          },
        },
        trialField: {
          eventType: actionData.eventType,
        },
      },
      navigation: {
        sort: [
          {
            key: 'order',
            order: 'ASC',
          },
        ],
        page: {
          from: 0,
          size: 1000,
        },
      },
    },
  };

  try {
    let [fields] = yield all([makeRequest(fieldData, actionData)]);
    fields = fields.data.results.map((item) => item.trialField);
    getMappedTrialFields(fields);
    yield put(actions.newLogOptionsSuccess(getMappedTrialFields(fields)));
  } catch (error) {
    yield put(actions.newLogOptionsFail(error.response));
  }
}
