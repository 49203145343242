import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { getInitialPlantGoalsParams } from '../../PlantGoals/constants';
import { urls, useRequest } from '../../../Common/ApiServices';
import { sortOrders } from '../../../Common/globalConstants';
import { getActual } from '../utils';

import CropModal from '../CropModal/CropModal';
import BreadCrumb from '../../../BreadCrumb/BreadCrumb';
import Footer from '../../../Common/Footer/Footer';
import PlantReadOnlyInfoModal from '../../PlantReadOnlyInfoModal/PlantReadOnlyInfoModal';

import styles from './PlantGoalResultsList.module.scss';

const PlantGoalResultsList = () => {
  const { id } = useParams();

  const [pagination, setPagination] = useState({ first: 0, rows: 20 });
  const [goalsResults, setGoalsResults] = useState([]);
  const [plantGoalsParams, setPlantGoalsParams] = useState(
    getInitialPlantGoalsParams(id)
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [sort, setSort] = useState({ field: 'plantName', order: 1 });
  const [frozenWidth, setFrozenWidth] = useState(250);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);

  const tableRef = useRef(null);
  const defaultDatePattern = useSelector(
    (state) => state.profileInfo?.dateFormat?.datePattern
  );

  const { isLoading, sendRequest } = useRequest({});

  const trialName = useLocation();
  const history = useHistory();

  const fetchGoals = useCallback(async () => {
    const requestData = {
      url: urls.SEARCH_PLANT_GOALS_RESULTS,
      method: 'POST',
      data: plantGoalsParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      const results = response.data.results.map((result) => {
        return {
          plantName: result.plant.name,
          plantId: result.plant.id,
          cropNumber: result.crop.number,
          cropId: result.crop.id,
          section: result.crop.section,
          field: result.field.label,
          daysAfterPlanting: result.goal.daysAfterPlanting,
          goal: result.goal.goal,
          actual: getActual(result, defaultDatePattern),
          logDays: result.log.logDaysAfterPlanting,
        };
      });
      setGoalsResults(results);
      setTotalRecords(response.data.page.total);
    }
    return response;
  }, [defaultDatePattern, plantGoalsParams, sendRequest]);

  const breadCrumbItems = [
    {
      label: 'Trials',
      command: () => {
        history.push('/trials');
      },
    },
    {
      label: localStorage.getItem('trialName') || trialName.state,
      command: () => {
        history.push(`/trial/${id}`);
      },
    },
    { label: 'Plant Goals Results' },
  ];

  const onPageSelect = useCallback(
    ({ first, rows }) => {
      setPagination({ first, rows });
      setPlantGoalsParams({
        query: plantGoalsParams.query,
        navigation: {
          sort: [{ key: sort.field, order: sortOrders[sort.order] }],
          page: { from: first, size: rows },
        },
      });
    },
    [plantGoalsParams, sort]
  );

  const onSort = useCallback(
    ({ sortField, sortOrder }) => {
      setSort({ field: sortField, order: sortOrder });
      setPlantGoalsParams({
        query: plantGoalsParams.query,
        navigation: {
          sort: [{ key: sortField, order: sortOrders[sortOrder] }],
          page: { from: pagination.first, size: pagination.rows },
        },
      });
    },
    [pagination, plantGoalsParams]
  );

  const sortFunc = () => tableRef?.current?.props.value || goalsResults;

  const onGlobalSearch = debounce((event) => {
    setPlantGoalsParams((oldParams) => {
      const { navigation, query } = oldParams;
      return {
        navigation: navigation,
        query: event.target.value
          ? { ...query, search: event.target.value }
          : { trial: query.trial },
      };
    });
  }, 500);

  const changeFrozenWidth = (event) => {
    if (event.column.columnKey === 'plantName') {
      setFrozenWidth(frozenWidth + event.delta);
    }
  };

  const plantBodyTemplate = (rowData) => {
    return (
      <button
        id={rowData.id}
        className={styles.linkStyle}
        onClick={() => setSelectedPlant(rowData)}
      >
        {rowData.plantName}
      </button>
    );
  };
  const cropBodyTemplate = (rowData) => {
    return (
      <button
        id={rowData.id}
        className={styles.linkStyle}
        onClick={() => setSelectedCrop(rowData)}
      >
        {rowData.cropNumber}
      </button>
    );
  };

  const header = useMemo(
    () => (
      <div className={styles.tableHeader}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => onGlobalSearch(e)}
            placeholder="Search..."
          />
        </span>
      </div>
    ),
    [onGlobalSearch]
  );

  useEffect(() => {
    fetchGoals();
  }, [fetchGoals]);

  return (
    <div className={classNames(styles.plantGoalResultsList, 'list-generic')}>
      <BreadCrumb items={breadCrumbItems} />
      {selectedPlant && (
        <PlantReadOnlyInfoModal
          selectedPlantId={selectedPlant.plantId}
          setSelectedPlant={setSelectedPlant}
        />
      )}
      {selectedCrop && (
        <CropModal
          selectedCropId={selectedCrop.cropId}
          setSelectedCrop={setSelectedCrop}
        />
      )}

      <DataTable
        ref={tableRef}
        className={classNames('table-generic p-datatable-sm')}
        value={goalsResults}
        header={header}
        resizableColumns
        columnResizeMode="expand"
        selectionMode="checkbox"
        cellSelection
        dataKey="id"
        loading={isLoading}
        reorderableColumns
        sortField={sort.field}
        sortOrder={sort.order}
        onSort={onSort}
        scrollable
        emptyMessage=""
        frozenWidth={frozenWidth + 'px'}
        onColumnResizeEnd={(event) => changeFrozenWidth(event)}
      >
        <Column
          field="plantName"
          sortField="plantName"
          reorderable
          columnKey="plantName"
          header="Plant Name"
          body={plantBodyTemplate}
          headerStyle={{ width: '250px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
          frozen
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="cropNumber"
          reorderable
          columnKey="cropNumber"
          header="Crop Number"
          body={cropBodyTemplate}
          headerStyle={{ width: '150px', height: '48px' }}
          sortable
          sortFunction={sortFunc}
        />
        <Column
          field="section"
          sortField="sectionName"
          reorderable
          columnKey="section"
          sortable
          sortFunction={sortFunc}
          header="Section"
          headerStyle={{ width: '200px', height: '48px' }}
        />
        <Column
          field="field"
          sortField="fieldName"
          reorderable
          columnKey="field"
          sortable
          sortFunction={sortFunc}
          header="Trial Field"
          headerStyle={{ width: '200px', height: '48px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="daysAfterPlanting"
          reorderable
          columnKey="daysAfterPlanting"
          header="Days After Planting"
          headerStyle={{ width: '150px', height: '48px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="goal"
          reorderable
          columnKey="goal"
          header="Goal"
          headerStyle={{ width: '150px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="actual"
          reorderable
          columnKey="actual"
          header="Actual"
          headerStyle={{ width: '150px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
        />
        <Column
          className="p-text-nowrap p-text-truncate"
          field="logDays"
          reorderable
          columnKey="logDays"
          header="Log Days"
          headerStyle={{ width: '150px', height: '48px' }}
          bodyStyle={{ height: '50px' }}
        />
      </DataTable>
      <Footer
        totalRecords={totalRecords}
        initParams={getInitialPlantGoalsParams(id)}
        params={plantGoalsParams}
        pagination={pagination}
        type="goals result"
        onPageSelect={onPageSelect}
      />
    </div>
  );
};

export default PlantGoalResultsList;
