export const GET_NEW_LOG_OPTIONS = 'GET_NEW_LOG_OPTIONS';
export const NEW_LOG_OPTIONS_REQUEST = 'NEW_LOG_OPTIONS_REQUEST';
export const NEW_LOG_OPTIONS_SUCCESS = 'NEW_LOG_OPTIONS_SUCCESS';
export const NEW_LOG_OPTIONS_FAIL = 'NEW_LOG_OPTIONS_FAIL';
export const CLEAR_NEW_LOG_OPTIONS = 'CLEAR_NEW_LOG_OPTIONS';

export const getNewLogOptions = ({
  id,
  logout,
  dispatch,
  isAuthenticated,
  getAccessTokenSilently,
  eventType,
}) => ({
  type: GET_NEW_LOG_OPTIONS,
  trialId: id,
  actionData: {
    logout: logout,
    dispatch: dispatch,
    isAuthenticated: isAuthenticated,
    getAccessTokenSilently: getAccessTokenSilently,
    eventType: eventType,
  },
});

export const newLogOptionsRequest = () => ({ type: NEW_LOG_OPTIONS_REQUEST });

export const newLogOptionsSuccess = (fields) => ({
  type: NEW_LOG_OPTIONS_SUCCESS,
  fields: fields,
});

export const newLogOptionsFail = (error) => ({
  type: NEW_LOG_OPTIONS_FAIL,
  error: error,
});

export const clearLogOptions = () => ({
  type: CLEAR_NEW_LOG_OPTIONS,
});
