import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { useFormik } from 'formik';
import classNames from 'classnames';

import { setPopUp } from '../../../../reduxStore/popUp/actions';
import { clearLogOptions } from '../../../../reduxStore/newLogOptions/actions';
import { popUp } from '../../../Common/globalConstants';
import { urls, useRequest } from '../../../Common/ApiServices';
import {
  getCreateLogRequestData,
  getFetchLogsData,
  modals,
} from '../constants';
import {
  getCropName,
  getDefaultValues,
  getFieldNameIds,
  getFieldValue,
  getNewLogFields,
  getValidationData,
} from '../../utils';

import DynamicField from './DynamicField/DynamicField';

import styles from './NewLog.module.scss';

const EventLogForm = ({
  displayModal,
  setDisplayModal,
  trialFields,
  fetchLogs,
}) => {
  const [log, setLog] = useState({});
  const [trialFieldsToUse, setTrialFieldsToUse] = useState([]);

  const dispatch = useDispatch();
  const { error, sendRequest } = useRequest({});

  const { id } = useParams();

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Do you want to close the form?\nAll changes will be lost.',
        accept,
      });
    } else {
      accept();
    }
  };

  const extractLogInfo = (results) => {
    const savedLogFields = results[0].log?.fields;
    setLog(results[0].log);

    const updatedList = trialFields.map((item) => {
      const savedItem = savedLogFields.find(
        (savedLog) => savedLog.id === item.id
      );

      return savedItem ? getFieldValue(savedItem, item) : item;
    });

    setTrialFieldsToUse(updatedList ? updatedList : []);
  };

  const fetchEventLogs = async (id) => {
    const requestData = {
      url: urls.SEARCH_LOGS,
      method: 'POST',
      data: getFetchLogsData(id, 'EVENT'),
    };
    return await sendRequest(requestData);
  };

  const publishLog = async (logId) => {
    const requestData = {
      url: urls.PUBLISH_LOG,
      method: 'POST',
      data: {
        log: {
          id: logId,
        },
      },
    };
    const response = await sendRequest(requestData);
    if (response) {
      dispatch(
        setPopUp({
          severity: popUp.severities.SUCCESS,
          summary: popUp.summary.SUCCESSFUL,
          detail: 'Log was created successfully.',
          life: 5000,
          sticky: null,
        })
      );
      fetchLogs();
    }
    onHide();
  };

  const createLog = async (data) => {
    const requestData = {
      url: urls.CREATE_LOG,
      method: 'POST',
      data: data,
    };
    const response = await sendRequest(requestData);
    if (response) {
      await extractLog(response.data.log.id);
    }
  };

  const updateLog = async (data) => {
    const requestData = {
      url: urls.UPDATE_EVENT_LOG,
      method: 'POST',
      data: data,
    };
    const response = await sendRequest(requestData);
    if (response) {
      await publishLog(log.id);
    }
  };

  const deleteLogImage = async (fieldId) => {
    const requestData = {
      url: urls.DELETE_EVENT_LOG_IMAGES,
      method: 'POST',
      data: {
        logId: log.id,
        imageId: {
          in: [fieldId],
        },
      },
    };

    await sendRequest(requestData);
  };

  const createLogImage = useCallback(
    async (images, field) => {
      const fieldIds = getFieldNameIds(trialFieldsToUse);
      const formData = new FormData();
      formData.append('logId', log.id);
      formData.append('fieldId', fieldIds[field]);
      formData.append('file', images);

      const requestData = {
        url: urls.CREATE_EVENT_LOG_IMAGE,
        method: 'POST',
        data: formData,
      };

      await sendRequest(requestData);
    },
    [trialFieldsToUse, log]
  );

  const updateLogImage = async (image, imageId) => {
    const formData = new FormData();
    formData.append('imageId', imageId);
    formData.append('file', image);

    const requestData = {
      url: urls.UPDATE_EVENT_LOG_IMAGE,
      method: 'POST',
      data: formData,
    };

    const response = await sendRequest(requestData);
    if (response) {
      const res = await fetchEventLogs(id);
      if (res) {
        extractLogInfo(res.data.results);
      }
    }
  };

  const extractLog = async (logId) => {
    const data = {
      log: {
        id: {
          is: logId,
        },
      },
    };
    const requestData = {
      url: urls.EXTRACT_LOG,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);
    if (response) {
      setLog(response.data.log);
    }
  };

  const initialValues = useMemo(
    () => getDefaultValues(trialFieldsToUse, log),
    [trialFieldsToUse]
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getValidationData(trialFieldsToUse),
    onSubmit: async (values) => {
      const data = {
        log: {
          id: log.id,
          note: values.note,
          fields: getNewLogFields(formik.values, trialFieldsToUse),
          versionNumber: log.version.number,
        },
      };
      await updateLog(data);
    },
  });

  const onHide = () => {
    dispatch(clearLogOptions());
    setDisplayModal('');
    formik.resetForm();
  };

  useEffect(() => {
    if (error) {
      setDisplayModal('');
      onHide();
    }
  }, [error]);

  useEffect(async () => {
    if (id && trialFields) {
      const response = await fetchEventLogs(id);
      if (!response?.data?.results?.length) {
        await createLog(getCreateLogRequestData(id, 'EVENT'));
        setTrialFieldsToUse(trialFields ? trialFields : []);
      } else {
        extractLogInfo(response.data.results);
      }
    }
  }, [id, trialFields]);

  return (
    <Fragment>
      <Dialog
        className={styles.logFormDialog}
        header="Event Log"
        visible={displayModal === modals.EVENT_LOG}
        onHide={() => confirm(formik.dirty)}
      >
        <Divider />
        <PromptIfDirty dirty={formik.dirty} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="crop" className="p-col-12 p-md-3 p-text-bold">
                Crop*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText id="crop" value={getCropName()} readOnly={true} />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="eventType"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Log Type*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText id="eventType" value="Event" readOnly={true} />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  id="note"
                  className={classNames(
                    formik.touched.note && formik.errors.note && 'p-invalid'
                  )}
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'note' } });
                  }}
                  value={formik.values.note}
                />
              </div>
            </div>
            {trialFieldsToUse.map((field) => {
              return (
                <DynamicField
                  key={field.id}
                  trialField={field}
                  formik={formik}
                  createLogImage={createLogImage}
                  deleteLogImage={deleteLogImage}
                  updateLogImage={updateLogImage}
                />
              );
            })}
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={classNames(styles.button, 'p-button-secondary')}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default EventLogForm;
