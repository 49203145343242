import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import PromptIfDirty from '../../Common/PromptIfDirty';
import { useFormik } from 'formik';
import DatePicker, { registerLocale } from 'datepicker-special-week-numbers';
import { enGB, enUS } from 'date-fns/locale';
import classNames from 'classnames';
import { initialTaskFormSchema, taskFormSchema } from './taskFormValidation';
import { getWeekNumber } from '../../Common/utils';
import { combineDateAndTime } from '../utils';
import { urls, useRequest } from '../../Common/ApiServices';
import {
  errorCodes,
  locales,
  datePickerTimeFormat,
  userRoles,
  popUp,
} from '../../Common/globalConstants';
import {
  getTaskParams,
  initialTaskInfo,
  allDayStart,
  allDayEnd,
  messageText,
} from '../constants';
import { getInitialApplicationsParams } from '../../Products/constants';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import styles from './TaskForm.module.scss';

registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);

const TaskForm = ({
  displayTaskModal,
  taskId,
  taskIsCreated,
  taskTypes,
  trials,
  products,
  taskManagers,
  taskStatuses,
  setTaskIsUpdated,
  setTaskIsCreated,
  setDisplayTaskModal,
  setSelectedCalendarTask,
  setSelectedTask,
  initialLocation,
}) => {
  const [taskInfo, setTaskInfo] = useState(initialTaskInfo);
  const [allDayChecked, setAllDayChecked] = useState(false);
  const [applications, setApplications] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({});

  const isTrialTracker = useSelector((state) => state.isTrialTracker);
  const permissions = useSelector((state) => state.permissions.permissions);

  const trialOptions =
    trials &&
    trials.map((trial) => {
      return { id: trial.id, name: trial.name };
    });

  const statusOptions = taskId
    ? taskStatuses
    : taskStatuses.filter((status) => status.status !== 'COMPLETED');

  const getTaskInfo = async () => {
    const requestData = {
      url: urls.EXTRACT_TASK,
      method: 'POST',
      data: getTaskParams(taskId),
    };
    const response = await sendRequest(requestData);

    const res = response.data;
    const taskInfo = {
      trial: { id: res?.trial?.id, name: res?.trial?.name },
      description: res?.task?.description,
      product: res?.product && {
        id: res?.product?.id,
        name: res?.product?.tradeName,
      },
      applicationCode: res?.product &&
        res?.application && {
          id: res?.application?.id,
          code: res?.application?.code,
        },
      taskManager: res?.taskManager && {
        id: res?.taskManager?.id,
        name: `${res?.taskManager?.firstName} ${res?.taskManager?.lastName}`,
      },
      status: res?.task?.status.status !== 'EMPTY' && res?.task?.status,
      type: res?.task?.type,
      note: res?.task?.note,
      startDate: res?.task?.startDate,
      startTime:
        res?.task?.startDate.substring(res?.task?.startDate.length - 10) ===
          'T00:00:00Z' &&
        res?.task?.endDate.substring(res?.task?.endDate.length - 10) ===
          'T23:59:59Z'
          ? null
          : res?.task?.startDate,
      endTime:
        res?.task?.endDate.substring(res?.task?.endDate.length - 10) ===
          'T23:59:59Z' &&
        res?.task?.startDate.substring(res?.task?.startDate.length - 10) ===
          'T00:00:00Z'
          ? null
          : res?.task?.endDate,
      endDate: res?.task?.endDate,
      allDay: res?.task?.allDay,
      version: res?.task?.version,
      dateFormat: res?.task?.dateFormat?.datePattern,
    };

    setAllDayChecked(res?.task?.allDay);
    setTaskInfo(taskInfo);
    setIsCompleted(res?.task?.status?.status === 'COMPLETED');

    return response;
  };

  const createTask = async (data) => {
    const requestData = {
      url: urls.CREATE_TASK,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);
    if (response) {
      setDisplayTaskModal(false);
      setTaskIsCreated(true);
    }
    return response;
  };

  const updateTask = async (data) => {
    const requestData = {
      url: urls.UPDATE_TASK,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);
    if (response) {
      setDisplayTaskModal(false);
      setIsCompleted(false);
      setTaskIsUpdated(true);
      setSelectedTask && setSelectedTask({});
      setSelectedCalendarTask && setSelectedCalendarTask('');
      setTaskInfo(initialTaskInfo);
      setAllDayChecked(false);
    }

    return response;
  };
  const getApplicationCodes = async (id) => {
    const requestData = {
      url: urls.SEARCH_PRODUCT_APPLICATIONS,
      method: 'POST',
      data: getInitialApplicationsParams(id),
    };
    const response = await sendRequest(requestData);

    const applications = response.data.results.map((app) => {
      return { id: app?.application?.id, code: app?.application?.code };
    });
    setApplications(applications);

    return response;
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    setIsCompleted(false);
    setDisplayTaskModal(false);
    setSelectedCalendarTask && setSelectedCalendarTask('');
    formik.resetForm();
    setTaskInfo(initialTaskInfo);
    setSelectedTask && setSelectedTask({});
    setAllDayChecked(false);
  };

  const formik = useFormik({
    initialValues: initialTaskFormSchema(taskInfo),
    enableReinitialize: true,
    validationSchema: taskFormSchema,
    onSubmit: (values) => {
      let newTaskParams = {
        trialId: values.trial?.id,
        description: values.description,
        productId: values.product?.id || null,
        applicationId: values.applicationCode?.id || null,
        taskManagerId: values.taskManager?.id || null,
        status: values.status?.status || null,
        type: values.type?.type || null,
        note: values.note,
        allDay: values.allDay,
        startDate: formik.values.allDay
          ? combineDateAndTime(values.startDate, allDayStart)
          : combineDateAndTime(values.startDate, values.startTime),
        endDate: formik.values.allDay
          ? combineDateAndTime(values.endDate, allDayEnd)
          : combineDateAndTime(values.endDate, values?.endTime),
      };
      if (taskId) {
        updateTask({
          ...newTaskParams,
          versionNumber: taskInfo?.version?.number,
          id: taskId,
        });
      } else {
        createTask(newTaskParams);
      }
    },
  });
  const selectedLocation =
    trials &&
    trials.find((trial) => {
      return trial.id === formik.values.trial?.id;
    });

  const firstDayOfWeek = selectedLocation
    ? selectedLocation?.firstDayOfWeek
    : initialLocation?.firstDay;

  const dateFormatting = selectedLocation
    ? selectedLocation?.dateFormat
    : initialLocation?.dateFormat;

  const timeFormatting = selectedLocation
    ? datePickerTimeFormat[selectedLocation?.dateFormat]
    : datePickerTimeFormat[initialLocation?.dateFormat];

  const CustomCalendarInput = forwardRef(
    ({ value, onClick, error, touch, touch2 }, ref) => (
      <Button
        label={value}
        className={classNames(
          error && (formik.touched[touch] || formik.touched[touch2])
            ? styles.calendarButtonError
            : styles.calendarButton,
          'p-button-text p-button-plain'
        )}
        icon="pi pi-calendar"
        iconPos="right"
        onClick={onClick}
        onBlur={() => {
          formik.handleBlur({ target: { name: touch } });
        }}
        ref={ref}
        disabled={isTrialTracker || isCompleted}
        type="button"
      />
    )
  );

  const manageAppCode = (id) => {
    return id
      ? getApplicationCodes(formik.values.product?.id)
      : formik?.setFieldValue('applicationCode', null);
  };

  useEffect(() => {
    if (displayTaskModal && taskId) {
      getTaskInfo();
      permissions &&
        setIsAdmin(permissions?.includes(userRoles.PPT_MANAGE_COMPANY));
    }
  }, [taskId, displayTaskModal]);

  useEffect(() => {
    if (!error && taskIsCreated) {
      onHide();
    }
  }, [taskIsCreated]);

  useEffect(() => {
    if (error) {
      if (error?.response?.data?.errors) {
        error?.response?.data?.errors.forEach((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
        if (
          error.response.data?.errors[0]?.code ===
            errorCodes.NOT_PROVIDED?.code &&
          (error.response.data?.errors[0]?.fieldName === 'productId' ||
            error.response.data?.errors[0]?.fieldName === 'applicationId')
        ) {
          dispatch(
            setPopUp({
              severity: popUp.severities.ERROR,
              summary: popUp.summary.ERROR,
              detail: messageText?.productNotProvided,
              life: 5000,
              sticky: null,
            })
          );
        }
      }
      setTaskIsCreated(false);
      setTaskIsUpdated(false);
    }
  }, [error]);

  useEffect(() => {
    displayTaskModal && manageAppCode(formik?.values?.product?.id);
  }, [formik.values.product]);

  return (
    <Fragment>
      <Dialog
        className={styles.taskFormDialog}
        header="Task Information"
        visible={displayTaskModal}
        onHide={() => confirm(formik.dirty)}
      >
        <PromptIfDirty dirty={formik.dirty} />
        <Divider />
        <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="trial" className="p-col-12 p-md-3 p-text-bold">
                Trial*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="trial"
                  onChange={formik.handleChange}
                  className={`${
                    !formik.values.isDropdownOpen &&
                    formik.errors.trial &&
                    formik.touched.trial &&
                    'p-invalid'
                  }`}
                  onBlur={() => {
                    formik?.handleBlur({ target: { name: 'trial' } });
                  }}
                  onShow={() => formik.setFieldValue('isDropdownOpen', true)}
                  onHide={() => formik.setFieldValue('isDropdownOpen', false)}
                  value={formik.values.trial}
                  disabled={isTrialTracker || isCompleted}
                  options={trialOptions}
                  optionLabel="name"
                />
                {!formik.values.isDropdownOpen &&
                formik.errors.trial &&
                formik.touched.trial ? (
                  <small id="trial-invalid" className="p-error p-d-block">
                    {formik.errors.trial}
                  </small>
                ) : (
                  <small
                    className={classNames(
                      (isTrialTracker || isCompleted) && styles.disabledStyle
                    )}
                  >
                    Trial is required.
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="description"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Task Description*
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={
                    formik.errors.description &&
                    formik.touched.description &&
                    'p-invalid'
                  }
                  id="description"
                  type="text"
                  rows="1"
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'description' } });
                  }}
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                  disabled={isTrialTracker || isCompleted}
                />
                {formik.errors.description && formik?.touched?.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : (
                  <small
                    className={classNames(
                      (isTrialTracker || isCompleted) && styles.disabledStyle
                    )}
                  >
                    Description is required.
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="type" className="p-col-12 p-md-3">
                Task Type
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  disabled={isTrialTracker || isCompleted}
                  options={taskTypes}
                  optionLabel="name"
                  showClear
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="product" className="p-col-12 p-md-3">
                Product
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="product"
                  onChange={(val) => {
                    formik.setFieldValue('product', val.value);
                    formik.setFieldValue('applicationCode', null);
                  }}
                  value={formik.values.product}
                  disabled={isTrialTracker || isCompleted}
                  options={products}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="appCode" className="p-col-12 p-md-3">
                Application Code
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="applicationCode"
                  onChange={formik.handleChange}
                  value={formik.values.applicationCode}
                  disabled={isTrialTracker || isCompleted}
                  options={formik.values.product && applications}
                  optionLabel="code"
                  filter
                  showClear
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="startDate"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Start Date*
              </label>
              <div className="p-col-6">
                <DatePicker
                  id="startDate"
                  onChange={(val) => {
                    formik.setFieldValue('startDate', val);
                  }}
                  className={
                    formik.errors.startDate &&
                    formik.touched.startDate &&
                    'picker-error'
                  }
                  selected={formik.values.startDate}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  aria-describedby="startDate"
                  dateFormat={dateFormatting}
                  locale={locales[firstDayOfWeek]}
                  disabled={isTrialTracker || isCompleted}
                  isClearable={!isTrialTracker}
                  customInput={
                    <CustomCalendarInput
                      error={formik?.errors?.startDate}
                      touch="startDate"
                    />
                  }
                />
                {formik.errors.startDate && formik.touched.startDate && (
                  <small id="startDate-invalid" className="p-error p-d-block">
                    {formik.errors?.startDate}
                  </small>
                )}
              </div>
              <label
                className={classNames(
                  'p-col-fixed',
                  styles.weekLabel,
                  (isTrialTracker || isCompleted) && styles.disabledStyle
                )}
              >
                Week
              </label>
              <div className={classNames('p-col-fixed', styles.weekInput)}>
                <InputText
                  value={getWeekNumber(formik.values.startDate, firstDayOfWeek)}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="startTime"
                className="p-col-12 p-md-3 p-text-bold"
              >
                {'Start Time*'}
              </label>
              <div className="p-col-6">
                <DatePicker
                  id="startTime"
                  className={classNames(
                    styles.calendarButton,
                    (isTrialTracker || isCompleted) && styles.disabledStyle,
                    formik.errors.startTime &&
                      formik.touched.startTime &&
                      !formik.values?.allDay &&
                      'picker-error',
                    (formik.values?.allDay || allDayChecked) && styles.noClicks
                  )}
                  onChange={(val) => {
                    if (!val) {
                      val = undefined;
                    }
                    formik.setTouched({
                      ...formik.touched,
                      ['startTime']: true,
                    });
                    formik.setFieldValue('startTime', val);
                    formik.handleChange;
                  }}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'startTime' } });
                  }}
                  selected={formik?.values?.startTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  aria-describedby="startTime"
                  dateFormat={timeFormatting}
                  timeFormat={timeFormatting}
                  disabled={
                    formik?.values?.allDay ||
                    allDayChecked ||
                    isTrialTracker ||
                    isCompleted
                  }
                />
                {formik.errors.startTime &&
                  formik.touched.startTime &&
                  !formik.values.allDay && (
                    <small id="startTime-invalid" className="p-error p-d-block">
                      {formik.errors.startTime}
                    </small>
                  )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="endDate" className="p-col-12 p-md-3 p-text-bold">
                End Date*
              </label>
              <div className="p-col-6">
                <DatePicker
                  id="endDate"
                  className={formik.errors.endDate && 'picker-error'}
                  onChange={(val) => {
                    formik.setFieldValue('endDate', val);
                  }}
                  selected={formik.values.endDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  aria-describedby="endDate-invalid"
                  disabled={isTrialTracker || isCompleted}
                  isClearable={!isTrialTracker}
                  locale={locales[firstDayOfWeek]}
                  customInput={
                    <CustomCalendarInput
                      error={formik.errors.endDate}
                      touch="endDate"
                      touch2="startDate"
                    />
                  }
                />
                {formik.errors.endDate &&
                  (formik.touched.endDate || formik.touched.startDate) && (
                    <small id="endDate-invalid" className="p-error p-d-block">
                      {formik.errors.endDate}
                    </small>
                  )}
              </div>
              <label
                className={classNames(
                  styles.weekLabel,
                  (isTrialTracker || isCompleted) && styles.disabledStyle,
                  'p-col-fixed'
                )}
              >
                Week
              </label>
              <div className={classNames('p-col-fixed', styles.weekInput)}>
                <InputText
                  value={getWeekNumber(formik.values.endDate, firstDayOfWeek)}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="endTime" className="p-col-12 p-md-3 p-text-bold">
                {'End Time*'}
              </label>
              <div className="p-col-6">
                <DatePicker
                  id="endTime"
                  className={classNames(
                    styles.calendarButton,
                    (isTrialTracker || isCompleted) && styles.disabledStyle,
                    formik.errors.endTime &&
                      (formik.touched.endTime || formik.touched.startTime) &&
                      !formik.errors.endDate &&
                      !formik.values.allDay &&
                      'picker-error',
                    (formik.values.allDay || allDayChecked) && styles.noClicks
                  )}
                  onChange={(val) => {
                    if (!val) {
                      val = undefined;
                    }
                    formik.setTouched({ ...formik.touched, ['endTime']: true });
                    formik.setFieldValue('endTime', val);
                    formik.handleChange;
                  }}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'endTime' } });
                  }}
                  selected={formik.values.endTime}
                  showTimeSelect
                  showTimeSelectOnly
                  aria-describedby="endTime"
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat={timeFormatting}
                  timeFormat={timeFormatting}
                  disabled={
                    formik.values.allDay ||
                    allDayChecked ||
                    isTrialTracker ||
                    isCompleted
                  }
                />
                {formik.errors.endTime &&
                  (formik.touched.endTime || formik.touched.startTime) &&
                  !formik.errors.endDate &&
                  !formik.values.allDay && (
                    <small id="endDate-invalid" className="p-error p-d-block">
                      {formik.errors.endTime}
                    </small>
                  )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <div className={classNames(styles.checkbox, 'p-field-checkbox')}>
                <Checkbox
                  id="allDay"
                  onChange={(val) => {
                    if (val.checked === true) {
                      formik.setFieldValue('endTime', undefined, true);
                      formik.setFieldValue('startTime', undefined, true);
                    } else if (val.checked === false) {
                      formik.setTouched({
                        ...formik.touched,
                        ['startTime']: true,
                        ['endTime']: true,
                      });
                    }
                    formik.setFieldValue('allDay', val.checked, true);
                    setAllDayChecked(val.checked);
                    formik.handleChange;
                  }}
                  value={allDayChecked ? allDayChecked : formik.values.allDay}
                  checked={allDayChecked ? allDayChecked : formik.values.allDay}
                  disabled={isTrialTracker || isCompleted}
                />
                <label
                  htmlFor="allDay"
                  className={classNames(
                    (isTrialTracker || isCompleted) && styles.disabledStyle
                  )}
                >
                  All Day
                </label>
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="status" className="p-col-12 p-md-3">
                Task Status
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  disabled={isTrialTracker || (isCompleted && !isAdmin)}
                  options={statusOptions}
                  optionLabel="name"
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="taskManager" className="p-col-12 p-md-3">
                Task Manager
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="taskManager"
                  onChange={formik.handleChange}
                  value={formik.values.taskManager}
                  disabled={isTrialTracker || isCompleted}
                  options={taskManagers}
                  optionLabel="name"
                  filter
                  showClear
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note && 'p-invalid'}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                  disabled={isTrialTracker || isCompleted}
                />
                {formik.errors.note && (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'p-grid p-col-12 p-justify-end pad-r-0 margin-l-0',
              isTrialTracker ? styles.hideElements : styles.showElements
            )}
          >
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              icon="pi pi-check"
              disabled={
                !formik.dirty ||
                formik.errors.trial ||
                formik.errors.description ||
                formik.errors.endDate ||
                formik.errors.startDate ||
                formik.errors.startTime ||
                formik.errors.endTime
              }
              autoFocus
            />
            <Button
              className={classNames('p-button-secondary', styles.button)}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default TaskForm;
