import React, { useRef } from 'react';

import { Button } from 'primereact/button';
import classNames from 'classnames';

import styles from './LogoUploader.module.scss';

const LogoUploader = ({ setSelectedImage, clearLogo, formik }) => {
  const filePicker = useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(file);
        formik.setFieldValue('logo', e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePick = () => {
    filePicker.current.click();
  };

  return (
    <div className={styles.logoUploader}>
      <div className={styles.logoButton} onClick={handlePick}>
        <img src={formik.values.logo} />
      </div>
      <Button
        type="button"
        icon="pi pi-times"
        className={classNames(
          styles.dangerButton,
          'p-button-outlined p-button-rounded p-ml-auto'
        )}
        onClick={clearLogo}
      />
      <input
        className={styles.hidden}
        ref={filePicker}
        type="file"
        onChange={handleChange}
        onClick={(event) => {
          event.target.value = null;
        }}
        accept="image/*"
      />
    </div>
  );
};

export default LogoUploader;
