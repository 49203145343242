import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { urls, useRequest } from '../../../Common/ApiServices';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { initialPlantGoalsInfo } from '../constants';
import {
  initialPlantGoalsFormSchema,
  plantGoalsFormSchema,
} from './plantGoalsFormValidation';
import { popUp } from '../../../Common/globalConstants';
import { setPopUp } from '../../../../reduxStore/popUp/actions';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { errorCodes } from '../../../Administration/constants';
import styles from './PlantGoalsForm.module.scss';

const PlantGoalsForm = ({
  showModal,
  setShowModal,
  fetchGoals,
  selectedGoalId,
  setSelectedGoalId,
}) => {
  const [plantOptions, setPlantOptions] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [plantInfo, setPlantInfo] = useState(initialPlantGoalsInfo);

  const dispatch = useDispatch();

  const { error, sendRequest } = useRequest({});

  const { id } = useParams();

  const createPlantGoal = useCallback(
    async (data) => {
      const requestData = {
        url: urls.CREATE_TRIAL_FIELD_GOALS,
        method: 'POST',
        data: data,
      };

      const response = await sendRequest(requestData);
      if (response) {
        setShowModal(false);
        fetchGoals();
        onHide();

        dispatch(
          setPopUp({
            severity: popUp.severities.SUCCESS,
            summary: popUp.summary.SUCCESSFUL,
            detail: 'New plant goal was created successfully!',
            life: 5000,
            sticky: null,
          })
        );
      }
      return response;
    },
    [dispatch, fetchGoals, sendRequest, setShowModal]
  );

  const updatePlantGoal = useCallback(
    async (data) => {
      const requestData = {
        url: urls.UPDATE_TRIAL_FIELD_GOALS,
        method: 'POST',
        data: data,
      };

      const response = await sendRequest(requestData);
      if (response) {
        setSelectedGoalId(null);
        setShowModal(false);
        fetchGoals();
        onHide();

        dispatch(
          setPopUp({
            severity: popUp.severities.SUCCESS,
            summary: popUp.summary.SUCCESSFUL,
            detail: 'Plant goal was updated successfully!',
            life: 5000,
            sticky: null,
          })
        );
      }
      return response;
    },
    [dispatch, fetchGoals, setSelectedGoalId, setShowModal]
  );

  const extractPlantGoal = useCallback(async (id) => {
    const requestData = {
      url: urls.EXTRACT_TRIAL_FIELD_GOAL,
      method: 'POST',
      data: {
        goal: {
          id: {
            is: id,
          },
        },
      },
    };

    const response = await sendRequest(requestData);
    if (response) {
      const plantInfo = {
        plantName: [response.data.plant],
        field: response.data.trialField,
        daysAfterPlanting: response.data.trialFieldGoal.daysAfterPlanting,
        goal: response.data.trialFieldGoal.goal,
      };
      setPlantInfo(plantInfo);
    }
    return response;
  }, []);

  const fetchTrialPlants = useCallback(async () => {
    const requestData = {
      url: urls.SEARCH_TRIAL_PLANTS_GOAL,
      method: 'POST',
      data: {
        query: {
          trial: {
            id: {
              is: id,
            },
          },
        },
      },
    };

    const response = await sendRequest(requestData);
    if (response) {
      const options = response.data.plants.map((plant) => ({
        id: plant.id,
        name: plant.name,
        description: plant.description ? plant.description : '',
      }));
      setPlantOptions(options);
    }
    return response;
  }, [id]);

  const fetchTrialFields = useCallback(async () => {
    const requestData = {
      url: urls.SEARCH_TRIAL_FIELDS_GOAL,
      method: 'POST',
      data: {
        query: {
          trial: {
            id: {
              in: [id],
            },
          },
        },
      },
    };

    const response = await sendRequest(requestData);
    if (response) {
      setFieldOptions(response.data.trialFields);
    }
    return response;
  }, [id]);

  const formik = useFormik({
    initialValues: initialPlantGoalsFormSchema(plantInfo),
    enableReinitialize: true,
    validationSchema: plantGoalsFormSchema,
    onSubmit: (values) => {
      const newPlantGoalsParams = selectedGoalId
        ? {
            ids: [selectedGoalId],
            goal: values.goal,
            daysAfterPlanting: values.daysAfterPlanting,
          }
        : {
            plant: {
              id: {
                in: values?.plantName?.map((plant) => plant.id),
              },
            },
            trialField: {
              id: {
                is: values.field.id,
              },
            },
            goal: values.goal,
            daysAfterPlanting: values.daysAfterPlanting,
          };
      selectedGoalId
        ? updatePlantGoal(newPlantGoalsParams)
        : createPlantGoal(newPlantGoalsParams);
    },
  });

  const onHide = useCallback(() => {
    setShowModal(false);
    setSelectedGoalId(null);
    formik.resetForm();
  }, [formik, setShowModal]);

  const accept = useCallback(() => {
    onHide();
  }, [onHide]);

  const confirm = useCallback(
    (dirty) => {
      if (dirty) {
        confirmDialog({
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          message: 'Are you sure you want to discard the changes?',
          accept,
        });
      } else {
        accept();
      }
    },
    [accept]
  );

  const fieldIsRequired = !selectedGoalId ? 'Field is required.' : '';

  useEffect(() => {
    selectedGoalId && extractPlantGoal(selectedGoalId);
  }, [extractPlantGoal, selectedGoalId]);

  useEffect(() => {
    fetchTrialPlants();
    fetchTrialFields();
  }, [fetchTrialFields, fetchTrialPlants]);

  useEffect(() => {
    if (error) {
      if (error.response.data.errors) {
        error.response.data.errors.forEach((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      }
    }
  }, [error, formik]);

  return (
    <Dialog
      className={styles.plantGoalsFormDialog}
      header="Plant Goal Information"
      visible={showModal}
      onHide={onHide}
    >
      <PromptIfDirty dirty={formik.dirty} />
      <Divider />
      <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
        <div className="p-fluid">
          {!selectedGoalId && (
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="plant" className="p-col-12 p-md-3 p-text-bold">
                Plant Name*
              </label>
              <div className="p-col-12 p-md-9">
                <MultiSelect
                  id="plantName"
                  className={classNames(
                    'p-multiselect',
                    !formik.values.isMultiselectOpen &&
                      formik.errors.plantName &&
                      formik.touched.plantName &&
                      'p-invalid'
                  )}
                  value={formik?.values?.plantName}
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik?.handleBlur({ target: { name: 'plantName' } });
                  }}
                  onShow={() => formik.setFieldValue('isMultiselectOpen', true)}
                  onHide={() =>
                    formik.setFieldValue('isMultiselectOpen', false)
                  }
                  aria-describedby="plantName-invalid"
                  options={plantOptions}
                  optionLabel="name"
                  display="chip"
                  filter
                  placeholder="Select Plants"
                />
                {!formik.values.isMultiselectOpen &&
                formik.errors.plantName &&
                formik.touched.plantName ? (
                  <small id="plantName-invalid" className="p-error p-d-block">
                    {formik.errors.plantName}
                  </small>
                ) : (
                  <small>Plant name is required.</small>
                )}
              </div>
            </div>
          )}
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="plant" className="p-col-12 p-md-3 p-text-bold">
              Field*
            </label>
            <div className="p-col-12 p-md-9">
              <Dropdown
                id="field"
                className={classNames(
                  'p-multiselect',
                  !formik.values.isDropdownOpen &&
                    formik.errors.field &&
                    formik.touched.field &&
                    'p-invalid'
                )}
                value={formik.values.field}
                aria-describedby="field-invalid"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik?.handleBlur({ target: { name: 'field' } });
                }}
                onShow={() => formik.setFieldValue('isDropdownOpen', true)}
                onHide={() => formik.setFieldValue('isDropdownOpen', false)}
                options={fieldOptions}
                optionLabel="label"
                display="chip"
                filter
                placeholder="Select Field"
                disabled={selectedGoalId}
              />
              {!formik.values.isDropdownOpen &&
              formik.errors.field &&
              formik.touched.field ? (
                <small id="field-invalid" className="p-error p-d-block">
                  {formik.errors.field}
                </small>
              ) : (
                <small>{fieldIsRequired}</small>
              )}
            </div>
          </div>
          {formik.values.field.eventType !== 'EVENT' ? (
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="productName"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Days After Planting*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="daysAfterPlanting"
                  type="number"
                  value={formik.values.daysAfterPlanting}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  min="0"
                />
                {formik.errors.daysAfterPlanting &&
                formik.touched.daysAfterPlanting ? (
                  <small id="goal-invalid" className="p-error p-d-block">
                    {formik.errors.daysAfterPlanting}
                  </small>
                ) : (
                  <small>Field is required.</small>
                )}
              </div>
            </div>
          ) : (
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="productName" className="p-col-12 p-md-3 ">
                Days After Planting
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="daysAfterPlanting"
                  type="number"
                  value={formik.values.daysAfterPlanting}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  min="0"
                  disabled
                />
              </div>
            </div>
          )}
          <div className="p-field p-grid p-ai-start">
            <label
              htmlFor="productName"
              className="p-col-12 p-md-3 p-text-bold"
            >
              Goal*
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="goal"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.goal}
              />
              {formik.errors.goal && formik.touched.goal ? (
                <small id="goal-invalid" className="p-error p-d-block">
                  {formik.errors.goal}
                </small>
              ) : (
                <small>Field is required.</small>
              )}
            </div>
          </div>
        </div>
        <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
          <Button
            label="Save"
            type="submit"
            icon="pi pi-check"
            disabled={!formik.dirty || !formik.isValid}
            autoFocus
          />
          <Button
            className="p-button-secondary"
            label="Cancel"
            type="button"
            icon="pi pi-times"
            onClick={() => confirm(formik.dirty)}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default PlantGoalsForm;
