import * as yup from 'yup';
import moment from 'moment';

export const initialCropFormSchema = (cropInfo) => {
  return {
    number: cropInfo.number,
    plant: cropInfo.plant || null,
    plantDate: cropInfo.plantDate
      ? moment(cropInfo.plantDate, 'YYYY-MM-DD').toDate()
      : null,
    section: cropInfo.section || null,
    quantity: cropInfo.quantity,
    dateFormat: cropInfo.dateFormat?.datePattern,
    startDate: cropInfo.startDate
      ? moment(cropInfo.startDate).toDate()
      : undefined,
    startForm: cropInfo.startForm || null,
    potSize: cropInfo.potSize || null,
    transplantDate: cropInfo.transplantDate
      ? new Date(cropInfo.transplantDate)
      : undefined,
    spaceDate1: cropInfo.spaceDate1
      ? moment(cropInfo.spaceDate1).toDate()
      : undefined,
    spaceDate2: cropInfo.spaceDate2
      ? moment(cropInfo.spaceDate2).toDate()
      : undefined,
    lotNumber: cropInfo.lotNumber || '',
    plantsPerPot: cropInfo.plantsPerPot || '',
    note: cropInfo.note || '',
    description: cropInfo.description || '',
  };
};

const lengthError = 'Maximum length exceeded.';

export const cropFormSchema = yup.object().shape({
  plant: yup.object().required('Plant Name should be provided.'),
  quantity: yup
    .number()
    .typeError('Quantity should be a number.')
    .required('Quantity should be provided.')
    .min(1, 'Quantity should be positive.')
    .max(999, 'Quantity can not be more than 999'),
  section: yup.object().required('Section should be provided.'),
  plantDate: yup.date().required('Plant Date should be provided.'),
  lotNumber: yup.string().max(255, lengthError),
  plantsPerPot: yup
    .number()
    .typeError('Plants per pot should be a number.')
    .min(1, 'Plants per pot should be positive.')
    .max(999, lengthError),
  note: yup.string().max(1024, lengthError),
  description: yup.string().max(1024, lengthError),
});
