import React, { useState, Fragment, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { initialFieldFormSchema, fieldFormSchema } from './FieldFormValidation';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import {
  options,
  initialFieldInfo,
  errorCodes,
  refTableHeaders,
} from './constants';
import { fieldTypesOptions } from '../../../Common/globalConstants';
import { urls, useRequest } from '../../../Common/ApiServices';
import styles from './FieldForm.module.scss';

const FieldForm = (props) => {
  const fieldParams = {
    query: {
      profileField: {
        id: {
          is: props.fieldId,
        },
      },
    },
  };

  const [fieldInfo, setFieldInfo] = useState(initialFieldInfo);
  const [initialFieldValues, setInitialFieldValues] = useState([]);
  const [initialFieldType, setInitialFieldType] = useState('NEW_FIELD');
  const [valuesChanged, setValuesChanged] = useState(false);
  const [currentRefTableName, setCurrentRefTableName] = useState('');
  const [isDisplayRefTable, setIsDisplayRefTable] = useState(false);

  const { error, sendRequest } = useRequest({});

  const groupsOptions = props?.isSystemField
    ? props.groups
    : props?.groups?.filter((group) => {
        if (group.name !== 'System') {
          return group;
        }
      });

  const formik = useFormik({
    initialValues: initialFieldFormSchema(fieldInfo),
    enableReinitialize: true,
    validationSchema: fieldFormSchema,
    onSubmit: (values) => {
      let newFieldParams = {
        name: values.name,
        label: values.label,
        type: values.dataType.name,
        eventType: values.fieldType.name,
        groupId: values?.group?.id,
      };
      if (values.dataType.name === 'SELECT' && fieldInfo.values.length > 0) {
        newFieldParams = {
          ...newFieldParams,
          ...{ values: fieldInfo.values, multiselect: values.multiselect },
        };
      }
      if (['INTEGER', 'NUMBER'].includes(values.dataType.name)) {
        newFieldParams = {
          ...newFieldParams,
          ...{ minValue: values.minValue, maxValue: values.maxValue },
        };
      }
      if (props.fieldId) {
        newFieldParams = {
          ...newFieldParams,
          ...{
            id: props.fieldId,
            versionNumber: fieldInfo.version?.number || 0,
          },
        };
        updateField(newFieldParams);
      } else {
        createField(newFieldParams);
      }
    },
  });

  const refTableHeader = () => {
    return currentRefTableName && refTableHeaders[currentRefTableName];
  };

  const refTableValues = {
    [urls.PROFILE_FIELD_GROUPS]: groupsOptions,
  };

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setIsDisplayRefTable(true);
  };

  const getFieldInfo = async () => {
    const requestData = {
      url: urls.EXTRACT_PROFILE_FIELD,
      method: 'POST',
      data: fieldParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      let dataField = response.data.profileField;
      dataField.dataType = { name: dataField.type };
      dataField.fieldType = { name: dataField.eventType };
      dataField.values = dataField.values || [];
      props.setFieldIsUpdated(false);
      setFieldInfo(dataField);
      setInitialFieldValues(JSON.parse(JSON.stringify(dataField.values)));
      setInitialFieldType(dataField.dataType.name);
    }
  };

  const createField = async (fieldParams) => {
    const requestData = {
      url: urls.CREATE_PROFILE_FIELD,
      method: 'POST',
      data: fieldParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      props.setFieldIsCreated(true);
    }
  };

  const updateField = async (fieldParams) => {
    const requestData = {
      url: urls.UPDATE_PROFILE_FIELD,
      method: 'POST',
      data: fieldParams,
    };

    const response = await sendRequest(requestData);

    if (response) {
      props.setFieldIsUpdated(true);
    }
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty || valuesChanged) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    props.setDisplayFieldModal(false);
    formik.resetForm();
    setFieldInfo(initialFieldInfo);
    setInitialFieldValues([]);
    props.setSelectedField(null);
    setValuesChanged(false);
    setInitialFieldType('NEW_FIELD');
  };

  const onValuesChange = (values) => {
    setFieldInfo((prevState) => ({
      ...prevState,
      values: values,
      group: formik.values?.group,
      dataType: formik.values?.dataType,
      fieldType: formik.values?.fieldType,
      name: formik.values?.name,
      label: formik.values?.label,
      multiselect: formik.values?.multiselect || false,
    }));
    setValuesChanged(true);
  };

  const fieldValuesTable = formik.values.dataType?.name === 'SELECT' && (
    <div>
      <div className="p-field p-grid p-ai-start">
        <div htmlFor="type" className="p-col-12 p-md-3" />
        <div className={`${styles.checkbox} p-col-12 p-md-9 p-field-checkbox`}>
          <Checkbox
            id="multiselect"
            onChange={formik.handleChange}
            value={formik.values.multiselect}
            checked={formik.values.multiselect}
            disabled={props.isSystemField}
          />
          <label htmlFor="multiSelect">Multi Select</label>
        </div>
      </div>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="values" className="p-col-12 p-md-3">
          Values
        </label>
        <div className="p-col-12 p-md-9">
          <ReferenceTable
            fieldId={props.fieldId}
            values={fieldInfo.values}
            referenceName="trialField"
            setValues={(values) => onValuesChange(values)}
            initialValues={initialFieldValues}
            setInitialValues={setInitialFieldValues}
            setValuesChanged={setValuesChanged}
            initialType={initialFieldType}
            editable={!props.isSystemField}
          />
        </div>
      </div>
    </div>
  );

  const minMaxFields = ['INTEGER', 'NUMBER'].includes(
    formik.values.dataType?.name
  ) && (
    <div>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="minValue" className="p-col-12 p-md-3">
          Min Value
        </label>
        <div className="p-col-12 p-md-9">
          <InputText
            id="minValue"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.minValue}
            aria-describedby="minValue-invalid"
            className={formik.errors.minValue ? 'p-invalid' : null}
            disabled={props.isSystemField}
          />
          {formik.touched.minValue && formik.errors.minValue && (
            <small id="minValue-invalid" className="p-error p-d-block">
              {formik.errors.minValue}
            </small>
          )}
        </div>
      </div>
      <div className="p-field p-grid p-ai-start">
        <label htmlFor="maxValue" className="p-col-12 p-md-3">
          Max Value
        </label>
        <div className="p-col-12 p-md-9">
          <InputText
            id="maxValue"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maxValue}
            aria-describedby="maxValue-invalid"
            className={formik.errors.maxValue ? 'p-invalid' : null}
            disabled={props.isSystemField}
          />
          {formik.touched.maxValue && formik.errors.maxValue && (
            <small id="maxValue-invalid" className="p-error p-d-block">
              {formik.errors.maxValue}
            </small>
          )}
        </div>
      </div>
    </div>
  );

  const submitButton = !props.isSystemField && (
    <Button
      className={styles.button}
      label="Save"
      type="submit"
      disabled={Boolean(
        (!formik.dirty &&
          !valuesChanged &&
          formik.values.dataType?.name === 'SELECT') ||
          (!formik.dirty && formik.values.dataType?.name !== 'SELECT') ||
          (formik.values.dataType?.name === 'SELECT' &&
            fieldInfo.values.length === 0) ||
          !formik.values.name ||
          !formik.values.label ||
          !formik.values.dataType ||
          !formik.values.fieldType ||
          formik.errors.name ||
          formik.errors.label ||
          formik.errors.dataType ||
          formik.errors.fieldType ||
          formik.errors.note
      )}
      icon="pi pi-check"
      autoFocus
    />
  );

  useEffect(() => {
    if (props.fieldId) {
      getFieldInfo();
    }
  }, [props.fieldId]);

  useEffect(() => {
    if (!error && (props.fieldIsCreated || props.fieldIsUpdated)) {
      onHide();
    }
  }, [error, props.fieldIsUpdated, props.fieldIsCreated]);

  useEffect(() => {
    props.setFieldIsCreated(false);

    if (error && error.response?.data?.errors) {
      if (
        error.response.data.errors[0].code === 'INVALID_VALUE' &&
        error.response.data.errors[0].fieldName === 'name'
      ) {
        formik.setFieldError(
          'name',
          'Only numbers, letters (a-Z) and underscore (_) are allowed.'
        );
      } else {
        error.response.data.errors.forEach((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      }
    }
  }, [error]);

  return (
    <Fragment>
      <Dialog
        className={styles.fieldFormDialog}
        header="Trial Field Information"
        visible={props.displayFieldModal}
        onHide={() => confirm(formik.dirty)}
      >
        <PromptIfDirty dirty={formik.dirty} />
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Field Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={
                    formik.errors.name && formik.touched.name
                      ? 'p-invalid'
                      : null
                  }
                  disabled={props.isSystemField}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                ) : (
                  <small>
                    Field Name is required{' '}
                    <i>
                      (only numbers, letters (a-Z) and underscore (_) are
                      allowed).
                    </i>
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="label" className="p-col-12 p-md-3 p-text-bold">
                Display Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="label"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.label}
                  aria-describedby="label-invalid"
                  className={
                    formik.errors.label && formik.touched.label
                      ? 'p-invalid'
                      : null
                  }
                  disabled={props.isSystemField}
                />
                {formik.touched.label && formik.errors.label ? (
                  <small id="label-invalid" className="p-error p-d-block">
                    {formik.errors.label}
                  </small>
                ) : (
                  <small>Display Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="dataType" className="p-col-12 p-md-3 p-text-bold">
                Data Type*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="dataType"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'dataType' } });
                  }}
                  onShow={() =>
                    formik.setFieldValue('isDataTypeDropdownOpen', true)
                  }
                  onHide={() =>
                    formik.setFieldValue('isDataTypeDropdownOpen', false)
                  }
                  value={formik.values.dataType}
                  aria-describedby="dataType-invalid"
                  className={`${
                    (props.error ||
                      (!formik.values.isDataTypeDropdownOpen &&
                        formik.errors.dataType &&
                        formik.touched.dataType)) &&
                    'p-invalid'
                  }`}
                  options={options[initialFieldType]}
                  optionLabel="name"
                  disabled={props.isSystemField}
                />
                {!formik.values.isDataTypeDropdownOpen &&
                formik.touched.dataType &&
                formik.errors.dataType ? (
                  <small id="dataType-invalid" className="p-error p-d-block">
                    {formik.errors.dataType}
                  </small>
                ) : (
                  <small>Data Type is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="fieldType"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Field Type*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="fieldType"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'fieldType' } });
                  }}
                  onShow={() =>
                    formik.setFieldValue('isFieldTypeDropdownOpen', true)
                  }
                  onHide={() =>
                    formik.setFieldValue('isFieldTypeDropdownOpen', false)
                  }
                  value={formik.values.fieldType}
                  aria-describedby="fieldType-invalid"
                  className={`${
                    (props.error ||
                      (!formik.values.isFieldTypeDropdownOpen &&
                        formik.errors.fieldType &&
                        formik.touched.fieldType)) &&
                    'p-invalid'
                  }`}
                  options={fieldTypesOptions}
                  optionLabel="name"
                  disabled={props.isSystemField}
                />
                {!formik.values.isFieldTypeDropdownOpen &&
                formik.touched.fieldType &&
                formik.errors.fieldType ? (
                  <small id="eventType-invalid" className="p-error p-d-block">
                    {formik.errors.fieldType}
                  </small>
                ) : (
                  <small>Field Type is required.</small>
                )}
              </div>
            </div>
            {fieldValuesTable}
            {minMaxFields}
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="type" className="p-col-12 p-md-3">
                Group
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="group"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'group' } });
                  }}
                  value={formik.values.group}
                  aria-describedby="group-invalid"
                  className={`${
                    (props.error ||
                      (formik.errors.group && formik.touched.group)) &&
                    'p-invalid'
                  }`}
                  options={groupsOptions}
                  optionLabel="name"
                  showClear
                  disabled={props.isSystemField}
                />
                {formik.touched.group && formik.errors.group ? (
                  <small id="type-invalid" className="p-error p-d-block">
                    {formik.errors.group}
                  </small>
                ) : null}
              </div>
              <div
                className={classNames(
                  'p-col-3 p-md-2',
                  props.isSystemField && styles.hideElement
                )}
              >
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable(urls.PROFILE_FIELD_GROUPS)}
                />
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            {submitButton}
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className="confirmDialog"
        header={refTableHeader()}
        visible={isDisplayRefTable}
        onHide={() => setIsDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
        />
        <div />
      </Dialog>
    </Fragment>
  );
};

export default FieldForm;
