import React, { useState, useEffect, Fragment, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import PromptIfDirty from '../../Common/PromptIfDirty';
import moment from 'moment';
import DatePicker from 'datepicker-special-week-numbers';
import { useFormik } from 'formik';
import classNames from 'classnames';
import {
  initialTrialFormSchema,
  trialFormSchema,
} from './newTrialFormValidation';
import { getDefaultDateFormat, getDefaultFirstDay } from '../../Common/utils';
import { locales, popUp } from '../../Common/globalConstants';
import ReferenceTable from '../../Common/ReferenceTable/ReferenceTable';
import { useRequest, urls } from '../../Common/ApiServices';
import { errorCodes } from '../constants';
import { initialProductParams } from '../../Products/constants';
import { setPopUp } from '../../../reduxStore/popUp/actions';
import StatusesTable from '../Statuses/StatusesTable';

import styles from './NewTrialForm.module.scss';

const NewTrialForm = ({
  locations,
  displayTrialModal,
  logFrequencies,
  trialIsCreated,
  setTrialIsCreated,
  setDisplayTrialModal,
  statuses,
}) => {
  const [isError, setIsError] = useState(false);
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [displayStatusesTable, setDisplayStatusesTable] = useState(false);
  const [products, setProducts] = useState([]);

  const employees = useSelector((state) => state.trial.employees);
  const cropTemplates = useSelector((state) => state.trial.cropTemplates);
  const dispatch = useDispatch();
  const { error, sendRequest } = useRequest({});

  const locationOptions = locations
    ? locations.map((location) => ({
        name: location?.location?.name,
        id: location?.location?.id,
      }))
    : null;

  const employeeOptions =
    employees &&
    employees.map((employee) => ({
      id: employee.employee.id.toString(),
      name: employee.employee.firstName + ' ' + employee.employee.lastName,
    }));

  const cropTemplatesOptions =
    cropTemplates &&
    cropTemplates.map((template) => {
      return {
        id: template.id,
        name: template.name,
        source: template.source,
      };
    });

  const responseFailed = (errorMessage = 'Something went wrong.') => {
    if (error?.response?.data?.errors) {
      error?.response?.data?.errors.map((err) =>
        formik.setFieldError(err.fieldName, errorCodes[err.code].text)
      );
    } else {
      dispatch(
        setPopUp({
          severity: popUp.severities.ERROR,
          summary: popUp.summary.ERROR,
          detail: errorMessage,
          life: 5000,
          sticky: null,
        })
      );
    }
  };

  const fetchProducts = async () => {
    const requestData = {
      url: urls.SEARCH_PRODUCTS,
      method: 'POST',
      data: initialProductParams,
    };
    const response = await sendRequest(requestData);

    if (response) {
      const products = response.data.results.map((product) => {
        return {
          id: product.product.id,
          name: product.product.name,
          tradeName: product.product.tradeName,
        };
      });
      setProducts(products);
    }

    return response;
  };

  const frequencies = logFrequencies || [];

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Do you want to close the form?\nAll changes will be lost.',
        accept,
      });
    } else {
      accept();
    }
  };

  const createTrial = async (data) => {
    const requestData = {
      url: urls.CREATE_TRIAL,
      method: 'POST',
      data: data,
    };

    const response = await sendRequest(requestData);
    if (response) {
      setIsError(false);
      setTrialIsCreated(true);
    }
    return response;
  };

  const formik = useFormik({
    initialValues: initialTrialFormSchema(locations, statuses),
    validationSchema: trialFormSchema,
    onSubmit: async (values) => {
      const newTrialParams = {
        name: values.name,
        locationId: values.location.id,
        trialManagerId: values.trialManager.id,
        logFrequencyId: values.logFrequency ? values.logFrequency.id : null,
        startDate: values.startDate
          ? moment(values.startDate).format('YYYY-MM-DD')
          : null,
        closeDate: values.closeDate
          ? moment(values.closeDate).format('YYYY-MM-DD')
          : null,
        description: values.description ? values.description : null,
        contract: values.contract,
        cropTemplateId: values.cropTemplate ? values.cropTemplate.id : null,
        note: values.note ? values.note : null,
        statusId: values.status ? values.status.id : null,
        products: {
          id: {
            in: values?.products?.map((product) => product.id),
          },
        },
      };
      createTrial(newTrialParams);
    },
  });

  const selectedLocation =
    locations &&
    locations.find((location) => {
      return location?.location?.id === formik.values?.location?.id;
    })?.location;

  const firstDayOfWeek = selectedLocation
    ? selectedLocation?.firstDayOfWeek?.day
    : getDefaultFirstDay();

  const dateFormatting = selectedLocation
    ? selectedLocation?.dateFormat?.datePattern
    : getDefaultDateFormat();

  const onHide = () => {
    setDisplayTrialModal(false);
    formik.resetForm();
  };

  const onOpenRefTable = () => {
    setDisplayRefTable(true);
  };

  const onOpenStatusesTable = () => {
    setDisplayStatusesTable(true);
  };

  const CustomCalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      label={value}
      className={classNames(
        styles.calendarButton,
        'p-button-text p-button-plain'
      )}
      icon="pi pi-calendar"
      iconPos="right"
      onClick={onClick}
      ref={ref}
      type="button"
    />
  ));

  useEffect(() => {
    if (error) {
      setIsError(true);
      setTrialIsCreated(false);
      responseFailed(errorCodes[error.response?.data?.error?.code]?.text);
    }
  }, [error]);

  useEffect(() => {
    if (formik.errors.name && formik.errors.name !== errorCodes.NOT_UNIQUE) {
    }
  }, [formik.errors.name]);

  useEffect(() => {
    if (!isError && trialIsCreated) {
      onHide();
    }
  }, [isError, trialIsCreated]);

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Fragment>
      <Dialog
        className={styles.trialFormDialog}
        header="Trial Information"
        visible={displayTrialModal}
        onHide={() => confirm(formik.dirty)}
      >
        <Divider />
        <PromptIfDirty dirty={formik.dirty} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="name" className="p-col-12 p-md-3 p-text-bold">
                Name*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  aria-describedby="name-invalid"
                  className={classNames(
                    formik.errors.name && formik.touched.name && 'p-invalid'
                  )}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small id="name-invalid" className="p-error p-d-block">
                    {formik.errors.name}
                  </small>
                ) : (
                  <small>Trial Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="location" className="p-col-12 p-md-3 p-text-bold">
                Location*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="location"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'location' } });
                  }}
                  value={formik.values.location}
                  aria-describedby="location-invalid"
                  className={classNames(
                    formik.errors.location &&
                      formik.touched.location &&
                      'p-invalid'
                  )}
                  options={locationOptions}
                  optionLabel="name"
                />
                {formik.touched.location && formik.errors.location ? (
                  <small id="location-invalid" className="p-error p-d-block">
                    {formik.errors.location}
                  </small>
                ) : (
                  <small>Trial Location is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="trialManager"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Trial Manager*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="trialManager"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'trialManager' } });
                  }}
                  onShow={() => formik.setFieldValue('isDropdownOpen', true)}
                  onHide={() => formik.setFieldValue('isDropdownOpen', false)}
                  value={formik.values.trialManager}
                  aria-describedby="trialManager-invalid"
                  className={classNames(
                    !formik.values.isDropdownOpen &&
                      formik.errors.trialManager &&
                      formik.touched.trialManager &&
                      'p-invalid'
                  )}
                  options={employeeOptions}
                  optionLabel="name"
                />
                {!formik.values.isDropdownOpen &&
                formik.touched.trialManager &&
                formik.errors.trialManager ? (
                  <small
                    id="trialManager-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.trialManager}
                  </small>
                ) : (
                  <small>Trial Manager is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="startDate" className="p-col-12 p-md-3">
                Start Date
              </label>
              <div className="p-col-8">
                <DatePicker
                  id="startDate"
                  onChange={(val) => {
                    formik.setFieldValue('startDate', val);
                  }}
                  selected={formik.values.startDate}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  dateFormat={dateFormatting}
                  locale={locales[firstDayOfWeek]}
                  isClearable
                  customInput={<CustomCalendarInput />}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="closeDate" className="p-col-12 p-md-3">
                Close Date
              </label>
              <div className="p-col-8">
                <DatePicker
                  className={classNames(
                    formik.errors.closeDate && 'picker-error'
                  )}
                  id="closeDate"
                  onChange={(val) => {
                    formik.setFieldValue('closeDate', val);
                  }}
                  selected={formik.values.closeDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable
                  aria-describedby="closeDate-invalid"
                  customInput={<CustomCalendarInput />}
                />
                {formik.errors.closeDate ? (
                  <small id="closeDate-invalid" className="p-error p-d-block">
                    {formik.errors.closeDate}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3">
                Description
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={classNames(
                    formik.errors.description && 'p-invalid'
                  )}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                />
                {formik.errors.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <div className={`${styles.checkbox} p-field-checkbox p-offset-3`}>
                <Checkbox
                  id="contract"
                  onChange={formik.handleChange}
                  checked={formik.values.contract}
                />
                <label htmlFor="contract">Contract</label>
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="logFrequency" className="p-col-12 p-md-3">
                Log Frequency
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="logFrequency"
                  onChange={formik.handleChange}
                  value={formik.values.logFrequency}
                  options={frequencies}
                  optionLabel="name"
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable()}
                />
              </div>
            </div>

            <div className="p-field p-grid p-ai-start">
              <label htmlFor="status" className="p-col-12 p-md-3 ">
                Status
              </label>

              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  options={statuses}
                  optionLabel="name"
                  showClear
                />
              </div>

              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenStatusesTable()}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="products" className="p-col-12 p-md-3">
                Products
              </label>
              <div className="p-col-9 p-md-7">
                <MultiSelect
                  id="products"
                  value={formik.values.products}
                  options={products}
                  onChange={formik.handleChange}
                  optionLabel="tradeName"
                  display="chip"
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="cropTemplate" className="p-col-12 p-md-3">
                Crop Label Template
              </label>
              <div className="p-col-12 p-md-5">
                <Dropdown
                  id="cropTemplate"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({
                      target: { name: 'cropTemplate' },
                    });
                  }}
                  value={formik.values.cropTemplate}
                  aria-describedby="cropTemplate-invalid"
                  className={`${
                    formik.errors.cropTemplate &&
                    formik.touched.cropTemplate &&
                    'p-invalid'
                  }`}
                  options={cropTemplatesOptions}
                  optionLabel="name"
                  showClear
                />
                {formik.touched.cropTemplate && formik.errors.cropTemplate && (
                  <small
                    id="cropTemplate-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.cropTemplate}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={classNames(formik.errors.note && 'p-invalid')}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={classNames(styles.button, 'p-button-secondary')}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className="confirmDialog"
        header="Log Frequencies"
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={frequencies}
          margin="20px"
          referenceName="logfrequencies"
          editable={true}
        />
      </Dialog>

      <Dialog
        className="confirmDialog"
        header="Trial Statuses"
        visible={displayStatusesTable}
        onHide={() => setDisplayStatusesTable(false)}
      >
        <StatusesTable values={statuses} />
      </Dialog>
    </Fragment>
  );
};

export default NewTrialForm;
