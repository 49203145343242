import { isArray, isObject } from 'lodash';
import moment from 'moment';
import {
  getInitialLogParams,
  nonEditableColumns,
} from './Logs/LogList/constants';
import { loadingStatus } from './constants';
import { dateFormat, fieldTypes, timeFormat } from '../Common/globalConstants';
import { getFormattedDate } from '../Common/utils';
import * as yup from 'yup';

export const getExportFieldNames = (selectedColumns) => {
  return [
    'plant_name',
    'crop_number',
    selectedColumns?.map((column) => {
      return column.field_ === 'owner_first_name'
        ? [column.field_, 'owner_last_name']
        : column.field_;
    }),
  ].flat(2);
};

export const getLogParams = (
  groupedBy,
  sort,
  filters,
  pagination,
  entity,
  logToEdit,
  globalSearch
) => {
  const sorters = [];

  let firstOrderSort = groupedBy.column;
  if (groupedBy.column === 'plantWeek') firstOrderSort = 'cropPlantWeek';
  if (firstOrderSort !== sort.field) {
    sorters.push({
      key: firstOrderSort,
      order: 'DESC',
    });
  }
  sorters.push({ key: sort.field, order: sort.order === 1 ? 'ASC' : 'DESC' });

  const query = getInitialLogParams(entity).query;
  if (globalSearch) {
    query.search = globalSearch;
  }
  const { plantDate, ...rest } = filters;
  if (plantDate) {
    query.crop.plantDate = plantDate;
  }
  if (logToEdit) {
    query.log = {
      id: {
        in: [logToEdit.id],
      },
    };
  }

  return {
    query: { ...query, ...rest },
    navigation: {
      sort: sorters,
      page: { from: pagination.first, size: pagination.rows },
    },
  };
};

export const getExportLogParams = (
  groupedBy,
  sort,
  filters,
  pagination,
  entity,
  logToEdit
) => {
  const sorters = [];

  let firstOrderSort = groupedBy.column;
  if (groupedBy.column === 'plantWeek') firstOrderSort = 'cropPlantWeek';
  if (firstOrderSort !== sort.field) {
    sorters.push({
      key: firstOrderSort,
      order: groupedBy.column === 'logWeek' ? 'DESC' : 'ASC',
    });
  }
  sorters.push({ key: sort.field, order: sort.order === 1 ? 'ASC' : 'DESC' });

  const query = getInitialLogParams(entity).query;

  const { plantDate, ...rest } = filters;
  if (plantDate) {
    query.crop.plantDate = plantDate;
  }
  if (logToEdit) {
    query.log = {
      id: {
        in: [logToEdit.id],
      },
    };
  }

  return {
    fieldsName: getExportFieldNames(selectedColumns),
    query: { ...query, ...rest },
    navigation: {
      sort: sorters,
      page: { from: pagination.first, size: pagination.rows },
    },
  };
};

export const getBreadCrumbItems = (
  history,
  isCropLogs,
  trialName,
  trialId,
  cropId,
  cropName,
  location
) => {
  return isCropLogs
    ? [
        {
          label: 'Trials',
          command: () => {
            history.push('/trials');
          },
        },
        {
          label: trialName || location.state,
          command: () => {
            history.push(`/trial/${trialId}`);
          },
        },
        {
          label: 'Crops',
          command: () => {
            history.push(`/trial/${trialId}/crops`);
          },
        },
        {
          label: cropName,
          command: () => {
            history.push(`/crop/${cropId}`);
          },
        },
        { label: 'Logs' },
      ]
    : [
        {
          label: 'Trials',
          command: () => {
            history.push('/trials');
          },
        },
        {
          label: trialName || location.state,
          command: () => {
            history.push(`/trial/${trialId}`);
          },
        },
        { label: 'Logs' },
      ];
};
const getFieldData = (field, trialFields, logToEdit, fieldsTypes) => {
  if (Array.isArray(logToEdit[field])) return;
  switch (true) {
    case fieldsTypes[field] === 'TIME':
      return [moment(logToEdit[field], 'HH:mm A').format('HH:mm:ss')];
    case fieldsTypes[field] === 'DATE_TIME':
      return [
        moment(
          logToEdit[field],
          `${dateFormat[logToEdit.datePattern]} HH:mm`
        ).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      ];
    case fieldsTypes[field] === 'DATE':
      return [
        moment(logToEdit[field], dateFormat[logToEdit.datePattern]).format(
          'yyy-MM-DD'
        ),
      ];
    case isObject(logToEdit[field]):
      return logToEdit[field].values;
    default:
      return [logToEdit[field]];
  }
};

export const getNewLogParams = (logToEdit, fieldsTypes, trialFields) => {
  const { id, note, versionNumber } = logToEdit;
  const newFields = Object.keys(logToEdit)
    .map((field) => ({
      id: trialFields.find((field_) => field_.name === field)?.id,
      values: getFieldData(field, trialFields, logToEdit, fieldsTypes),
    }))
    .filter((el) => el.id && el.values);
  return {
    log: {
      id: id,
      note: note,
      versionNumber: versionNumber,
      fields: newFields.filter((el) => el.values[0] || el.values[0] === false),
    },
  };
};

export const getMappedLogs = (data, customCols, imageCols, checkboxCols) => {
  return data.map(({ crop, owner, log, plant, section }) => {
    let initialLogs = {
      id: log.id,
      cropPlantName: plant.name,
      createDateSort: getFormattedDate(
        log.createDate,
        dateFormat[log.dateFormat.datePattern]
      ),
      datePattern: log.dateFormat.datePattern,
      createDate: getFormattedDate(
        log.createDate,
        dateFormat[log.dateFormat.datePattern]
      ),
      logWeek: log.logWeek ? log.logWeek : '',
      cropPlantDateSort: getFormattedDate(
        crop.plantDate,
        dateFormat[crop.dateFormat.datePattern]
      ),
      cropPlantDate: crop.plantDate,
      plantWeek: crop.plantWeek,
      cropSectionName: section.name,
      ownerId: `${owner.firstName} ${owner.lastName}`,
      companyId: log.companyId,
      note: log.note,
      versionNumber: log.version?.number || 0,
      eventType: log.eventType,
    };
    if (log.fields) {
      log.fields.forEach((field) => {
        customCols.push({
          field: field.name,
          header: field.label,
          field_: field.name,
          type: field.type,
        });
        if (field.type === 'IMAGE') {
          imageCols.push(field.name);
          initialLogs[field.name] = [];
          field.images?.forEach((image) => {
            initialLogs[field.name] = [
              ...initialLogs[field.name],
              {
                img: image.full,
                imgTmbn: image.thumbnail,
                imgId: image.id,
              },
            ];
          });
        } else initialLogs[field.name] = field.singleValue;
        if (field.type === 'CHECKBOX') {
          checkboxCols.push(field.name);
        }
        if (field.type === 'DATE_TIME') {
          initialLogs[field.name] = moment
            .utc(field.singleValue)
            .format(
              `${dateFormat[log.dateFormat.datePattern]} ${
                timeFormat[log.dateFormat.datePattern]
              }`
            );
          nonEditableColumns.push(field.name);
        }
        if (field.type === 'TIME') {
          initialLogs[field.name] = moment
            .utc(`01 Jun 2020 ${field.singleValue} -0000`)
            .format(`${timeFormat[log.dateFormat.datePattern]}`);
        }
        if (field.type === 'DATE') {
          initialLogs[field.name] = getFormattedDate(
            field.singleValue,
            dateFormat[log.dateFormat.datePattern]
          );
          nonEditableColumns.push(field.name);
        }

        if (field.type === 'TEXT') {
          initialLogs[field.name] = [];
          if (field.values) {
            field.values.forEach((value) => {
              initialLogs[field.name] = [
                ...initialLogs[field.name],
                value.name,
              ];
            });
            initialLogs[field.name] = initialLogs[field.name].join(', ');
          } else initialLogs[field.name] = field.singleValue;
        }
        if (field.type === 'SELECT') {
          initialLogs[field.name] = [];
          if (field.values) {
            field.values.forEach((value) => {
              initialLogs[field.name] = [...initialLogs[field.name], value];
            });
          }
        }
      });
    }
    return initialLogs;
  });
};

export const checkImageStatus = (statuses) => {
  return statuses
    .map((status) => status.status)
    .every((status) => {
      return status === loadingStatus.GENERATED;
    });
};

export const getMappedTrialFields = (trialFields) => {
  const typeValues = {
    DATE: null,
    DATE_TIME: null,
    TIME: null,
    TEXT: '',
    NOTE: '',
    INTEGER: null,
    NUMBER: null,
    CHECKBOX: false,
    IMAGE: {},
    SELECT: null,
  };

  return trialFields.map((field) => {
    return { ...field, value: typeValues[field.type] };
  });
};

export const getDefaultValues = (trialFields, log) => {
  const defaultFields = trialFields.map((field) => {
    return {
      name: field.name,
      value:
        field.type === 'INTEGER' || field.type === 'NUMBER'
          ? +field.value
          : field.value,
    };
  });
  const result = defaultFields.reduce((acc, item) => {
    acc[item.name] = item.value;
    return acc;
  }, {});

  return {
    note: log ? log.note : '',
    ...result,
  };
};

export const getFieldNameTypes = (trialFields) => {
  return trialFields.reduce((acc, obj) => {
    const { name, type } = obj;
    acc[name] = type;
    return acc;
  }, {});
};

export const getFieldNameIds = (trialFields) => {
  return trialFields.reduce((acc, item) => {
    acc[item.name] = item.id;
    return acc;
  }, {});
};

export const getNewLogFields = (values, trialFields) => {
  const typesFieldNames = getFieldNameTypes(trialFields);
  const fieldsForUse = Object.keys(values)
    .filter((val) => {
      if (
        val !== 'eventType' &&
        val !== 'note' &&
        val !== 'isEventTypeDropdownOpen' &&
        typesFieldNames[val] !== fieldTypes.IMAGE
      ) {
        return val;
      }
    })
    .reduce((obj, key) => {
      obj[key] = values[key];

      return obj;
    }, {});

  const notNullishFields = Object.fromEntries(
    Object.entries(fieldsForUse).filter(
      ([, value]) => value !== null && value !== '' && value !== 0
    )
  );

  const getValues = (key) => {
    if (
      typesFieldNames[key] === fieldTypes.DATE ||
      typesFieldNames[key] === fieldTypes.DATE_TIME
    ) {
      return [moment(fieldsForUse[key]).toISOString()];
    }
    if (typesFieldNames[key] === fieldTypes.TIME) {
      return [moment(fieldsForUse[key]).format('HH:mm:ss')];
    } else if (typesFieldNames[key] === fieldTypes.SELECT) {
      return isArray(fieldsForUse[key])
        ? fieldsForUse[key].map((item) => item.id)
        : [fieldsForUse[key].id];
    }
    return [fieldsForUse[key]];
  };

  return Object.keys(notNullishFields).map((key) => ({
    id: getFieldNameIds(trialFields)[key],
    values: getValues(key),
  }));
};

export const getValidationData = (trialFields) => {
  const typeValues = {
    DATE: yup.date(),
    DATE_TIME: yup.date(),
    TIME: yup.date(),
    TEXT: yup.string(),
    NOTE: yup.string(),
    INTEGER: yup.number(),
    NUMBER: yup.number(),
    CHECKBOX: yup.boolean(),
    IMAGE: yup.object(),
    SELECT: yup.object(),
  };

  const arr = trialFields.map((field) => {
    return {
      name: field.name,
      validation: field.required
        ? typeValues[field.type]
            .nullable()
            .required(`${field.label} should be provided.`)
        : typeValues[field.type].nullable(),
    };
  });

  const shape = {
    note: yup.string(),
  };

  arr.forEach((field) => {
    shape[field.name] = field.validation;
  });

  return yup.object().shape(shape);
};

export const getAllZones = (value, options) => {
  const allOptions = options ? [...options] : [];
  let copyValue = value && { ...value };
  if (copyValue) {
    const res = _.find(options, function (val) {
      return _.isEqual(copyValue, val);
    });
    !res && allOptions.push(copyValue);
  }
  return allOptions;
};

export const getMappedPlantResponse = (data) => ({
  name: data.plant.name,
  code: data.plant.code,
  patentNumber: data.plant.patentNumber,
  tradeName: data.plant.tradeName,
  scientificName: data.plant.scientificName,
  color: data.plant.color,
  active: data.plant.active,
  description: data.plant.description,
  note: data.plant.note,
  species: data.plant.species,
  origin: data.plant.origin,
  variety: data.plant.variety,
  stockRequired: data.plant.stockRequired,
  yieldEstimation: data.plant.yieldEstimation,
  image: data.plant.image,
  hardinessZone: data.plant.hardinessZone,
  pdfFile: data.plant.pdfFile,
  breeder: data.breeder,
  program: data.program,
  series: data.series,
  marketStatus: data.marketStatus,
  genus: data.genus,
});

export const getCropName = () => {
  const cropId = localStorage.getItem('cropId');
  const cropName = localStorage.getItem('cropName');
  const cropWeek = localStorage.getItem('cropWeek');
  return `${cropName}-${cropId}-${cropWeek.split('-')[1]}`;
};

export const getFieldValue = (savedItem, item) => {
  if (savedItem.singleValue) {
    switch (savedItem.type) {
      case fieldTypes.DATE:
      case fieldTypes.DATE_TIME:
        return {
          ...item,
          value: moment(savedItem.singleValue.toString()).toDate(),
        };
      case fieldTypes.TIME:
        return {
          ...item,
          value: moment(`2024.01.01 ${savedItem.singleValue}`).toDate(),
        };
      case fieldTypes.CHECKBOX:
        return { ...item, value: savedItem.singleValue === 'true' };
      default:
        return { ...item, value: savedItem.singleValue };
    }
  }
  if (savedItem.values) {
    return savedItem.multiselect
      ? { ...item, value: savedItem.values }
      : { ...item, value: savedItem.values[0] };
  }
  if (savedItem.images) {
    return { ...item, value: savedItem.images[0] };
  }
  return item;
};
