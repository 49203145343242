import React, { useState, useEffect, Fragment, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { initialCropFormSchema, cropFormSchema } from './newCropFormValidation';
import PromptIfDirty from '../../../Common/PromptIfDirty';
import { getWeekNumber } from '../../../Common/utils';
import { locales } from '../../../Common/globalConstants';
import DatePicker, { registerLocale } from 'datepicker-special-week-numbers';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import enGB from 'date-fns/locale/en-GB';
import urls from '../../../Common/ApiServices/urls';
import { modals } from '../constants';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';
import { useRequest } from '../../../Common/ApiServices';
import styles from './NewCropForm.module.scss';

registerLocale('en-GB', enGB);

const NewCropForm = ({
  trialId,
  cropPlants,
  cropSections,
  startForms,
  potSizes,
  cropError,
  displayModal,
  setDisplayModal,
  setCropIsCreated,
  cropIsCreated,
  isTrialTracker,
}) => {
  const savedTrialId = localStorage.getItem('trialId');

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [displayRefTable, setDisplayRefTable] = useState(false);
  const [currentRefTableName, setCurrentRefTableName] = useState('');

  const firstDayOfWeek = useSelector(
    (state) => state.crop?.location?.firstDayOfWeek?.day
  );

  const dateFormatting = useSelector(
    (state) => state.crop?.location?.dateFormat?.datePattern
  );

  const { error, sendRequest } = useRequest({});

  const plants = cropPlants
    ? cropPlants.map((plant) => ({
        name: plant.plant.name,
        id: plant.plant.id,
        description: plant.plant.description,
      }))
    : [];

  const sections = cropSections
    ? cropSections.map((section) => ({
        name: section.section.name,
        id: section.section.id,
        description: section.section.description,
      }))
    : [];

  const errorCodes = {
    NOT_PROVIDED: 'This field should be provided.',
    VALUE_SHOULD_BE_POSITIVE: 'Quantity should be positive.',
    INVALID_RANGE: 'Close Date should be after Start Date.',
    MAX_LENGTH_EXCEEDED: 'Maximum length exceeded.',
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Do you want to close the form?\nAll changes will be lost.',
        accept,
      });
    } else {
      accept();
    }
  };

  const formik = useFormik({
    initialValues: initialCropFormSchema(),
    validationSchema: cropFormSchema,
    onSubmit: async (values) => {
      const newCropParams = {
        trialId: trialId,
        plantIds: values.plants.map((plant) => plant.id),
        sectionId: values.section.id,
        lotNumber: values.lotNumber || null,
        plantsPerPot: values.plantsPerPot || null,
        startFormId: values.startForm && values.startForm.id,
        potSizeId: values.potSize && values.potSize.id,
        plantDate: values.plantDate
          ? moment(values.plantDate).format('YYYY-MM-DD')
          : null,
        startDate: values.startDate
          ? moment(values.startDate).format('YYYY-MM-DD')
          : null,
        spaceDate1: values.spaceDate1
          ? moment(values.spaceDate1).format('YYYY-MM-DD')
          : null,
        spaceDate2: values.spaceDate2
          ? moment(values.spaceDate2).format('YYYY-MM-DD')
          : null,
        transplantDate: values.transplantDate
          ? moment(values.transplantDate).format('YYYY-MM-DD')
          : null,
        quantity: values.quantity,
        transferCropNumber: null,
        note: values.note ? values.note : null,
        description: values.description ? values.description : null,
      };
      setIsSubmitted(true);

      const requestData = {
        url: urls.CREATE_CROP,
        method: 'POST',
        data: newCropParams,
      };
      const response = await sendRequest(requestData);
      if (response) {
        setCropIsCreated(true);
      }
    },
  });

  const onHide = () => {
    setDisplayModal('');
    setIsSubmitted(false);
    formik.resetForm();
  };

  const refTableHeader = () => {
    if (currentRefTableName === 'startforms') {
      return 'Start forms';
    } else if (currentRefTableName === 'pot-sizes') {
      return 'Pot Sizes';
    }
    return currentRefTableName;
  };

  const refTableValues = {
    [urls.START_FORMS]: startForms,
    [urls.POT_SIZES]: potSizes,
  };

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  const CustomCalendarInput = forwardRef(
    ({ value, onClick, error, touch }, ref) => (
      <Button
        label={value}
        className={classNames(
          error && formik.touched[touch]
            ? styles.calendarButtonError
            : styles.calendarButton,
          'p-button-text p-button-plain'
        )}
        icon="pi pi-calendar"
        iconPos="right"
        onClick={onClick}
        onBlur={() => {
          formik.handleBlur({ target: { name: touch } });
        }}
        ref={ref}
        type="button"
      />
    )
  );

  useEffect(() => {
    if (!error && cropIsCreated) {
      onHide();
    }
  }, [error, cropIsCreated]);

  useEffect(() => {
    if (error) {
      if (error.response.data.errors) {
        error.response.data.errors.forEach((err) =>
          formik.setFieldError(err.fieldName, errorCodes[err.code])
        );
      }
    }
  }, [error]);

  return (
    <Fragment>
      <Dialog
        className={styles.cropFormDialog}
        header="Crop Information"
        visible={displayModal === modals.CROP}
        onHide={() => confirm(formik.dirty)}
      >
        <Divider />
        <PromptIfDirty dirty={formik.dirty} />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="plant" className="p-col-12 p-md-3 p-text-bold">
                Plant Name*
              </label>
              <div className="p-col-12 p-md-9">
                <MultiSelect
                  id="plants"
                  className={classNames(
                    'p-multiselect',
                    (cropError ||
                      (!formik.values.isMultiselectOpen &&
                        formik.errors.plants &&
                        formik.touched.plants)) &&
                      'p-invalid'
                  )}
                  value={formik.values.plants}
                  aria-describedby="plant-invalid"
                  options={plants}
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'plants' } });
                  }}
                  onShow={() => formik.setFieldValue('isMultiselectOpen', true)}
                  onHide={() =>
                    formik.setFieldValue('isMultiselectOpen', false)
                  }
                  optionLabel="name"
                  display="chip"
                  filter
                  placeholder="Select Plants"
                />
                {!formik.values.isMultiselectOpen &&
                formik.touched.plants &&
                formik.errors.plants ? (
                  <small id="plant-invalid" className="p-error p-d-block">
                    {formik.errors.plants}
                  </small>
                ) : (
                  <small>Plant Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="plantDate"
                className="p-col-12 p-md-3 p-text-bold"
              >
                Plant Date*
              </label>
              <div className="p-col-5">
                <DatePicker
                  id="plantDate"
                  onChange={(val) => {
                    formik.setFieldValue('plantDate', val);
                  }}
                  className={
                    formik.errors.plantDate &&
                    formik.touched.plantDate &&
                    'picker-error'
                  }
                  selected={formik.values.plantDate}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  dateFormat={dateFormatting}
                  locale={locales[firstDayOfWeek]}
                  customInput={
                    <CustomCalendarInput
                      error={formik?.errors?.plantDate}
                      touch="plantDate"
                    />
                  }
                />
                {formik.touched.plantDate && formik.errors.plantDate ? (
                  <small id="plantDate-invalid" className="p-error p-d-block">
                    {formik.errors.plantDate}
                  </small>
                ) : (
                  <small>Plant Date is required.</small>
                )}
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '65px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(formik.values.plantDate, firstDayOfWeek)}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="section" className="p-col-12 p-md-3 p-text-bold">
                Section*
              </label>
              <div className="p-col-12 p-md-9">
                <Dropdown
                  id="section"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.handleBlur({ target: { name: 'section' } });
                  }}
                  onShow={() => formik.setFieldValue('isDropdownOpen', true)}
                  onHide={() => formik.setFieldValue('isDropdownOpen', false)}
                  value={formik.values.section}
                  aria-describedby="section-invalid"
                  className={`${
                    (cropError ||
                      (!formik.values.isDropdownOpen &&
                        formik.errors.section &&
                        formik.touched.section)) &&
                    'p-invalid'
                  }`}
                  options={sections}
                  optionLabel="name"
                  filter
                />
                {!formik.values.isDropdownOpen &&
                formik.touched.section &&
                formik.errors.section ? (
                  <small id="section-invalid" className="p-error p-d-block">
                    {formik.errors.section}
                  </small>
                ) : (
                  <small>Section is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="quantity" className="p-col-12 p-md-3 p-text-bold">
                Quantity*
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="quantity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  aria-describedby="quantity-invalid"
                  className={`${
                    (cropError ||
                      (formik.errors.quantity && formik.touched.quantity)) &&
                    'p-invalid'
                  }`}
                />
                {formik.touched.quantity && formik.errors.quantity ? (
                  <small id="quantity-invalid" className="p-error p-d-block">
                    {formik.errors.quantity}
                  </small>
                ) : (
                  <small>Quantity is required.</small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="startForm" className="p-col-12 p-md-3">
                Start Form
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="startForm"
                  onChange={formik.handleChange}
                  value={formik.values.startForm}
                  options={startForms}
                  optionLabel="name"
                  showClear
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable('startforms')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="startDate" className="p-col-12 p-md-3">
                Start Date
              </label>
              <div className="p-col-5">
                <DatePicker
                  id="startDate"
                  onChange={(val) => {
                    formik.setFieldValue('startDate', val);
                  }}
                  selected={formik.values.startDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable
                  customInput={<CustomCalendarInput />}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '65px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(formik.values.startDate, firstDayOfWeek)}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="transplantDate" className="p-col-12 p-md-3">
                Transplant Date
              </label>
              <div className="p-col-5">
                <DatePicker
                  id="transplantDate"
                  onChange={(val) => {
                    formik.setFieldValue('transplantDate', val);
                  }}
                  selected={formik.values.transplantDate}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable
                  customInput={<CustomCalendarInput />}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '65px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(
                    formik.values.transplantDate,
                    firstDayOfWeek
                  )}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="spaceDate1" className="p-col-12 p-md-3">
                Space Date 1
              </label>
              <div className="p-col-5">
                <DatePicker
                  id="spaceDate1"
                  onChange={(val) => {
                    formik.setFieldValue('spaceDate1', val);
                  }}
                  selected={formik.values.spaceDate1}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable
                  customInput={<CustomCalendarInput />}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '65px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(
                    formik.values.spaceDate1,
                    firstDayOfWeek
                  )}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="spaceDate2" className="p-col-12 p-md-3">
                Space Date 2
              </label>
              <div className="p-col-5">
                <DatePicker
                  id="spaceDate2"
                  onChange={(val) => {
                    formik.setFieldValue('spaceDate2', val);
                  }}
                  selected={formik.values.spaceDate2}
                  dateFormat={dateFormatting}
                  weekLabel={'Wk'}
                  showWeekNumbers
                  locale={locales[firstDayOfWeek]}
                  isClearable
                  customInput={<CustomCalendarInput />}
                />
              </div>
              <label
                className={`${styles.weekLabel} p-col-fixed`}
                style={{ width: '65px' }}
              >
                Week
              </label>
              <div className="p-col-fixed" style={{ width: '70px' }}>
                <InputText
                  value={getWeekNumber(
                    formik.values.spaceDate2,
                    firstDayOfWeek
                  )}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="lotNumber" className="p-col-12 p-md-3">
                Lot Number
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  className={formik.errors.lotNumber ? 'p-invalid' : null}
                  id="lotNumber"
                  type="text"
                  onChange={formik.handleChange}
                  aria-describedby="lotNumber-invalid"
                />
                {formik.errors.lotNumber ? (
                  <small id="lotNumber-invalid" className="p-error p-d-block">
                    {formik.errors.lotNumber}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="potSize" className="p-col-12 p-md-3">
                Pot Size
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="potSize"
                  onChange={formik.handleChange}
                  value={formik.values.potSize}
                  options={potSizes}
                  optionLabel="name"
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  onClick={() => onOpenRefTable('pot-sizes')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="plantsPerPot" className="p-col-12 p-md-3">
                Plants per Pot
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  className={formik.errors.plantsPerPot ? 'p-invalid' : null}
                  id="plantsPerPot"
                  type="text"
                  value={formik.values.plantsPerPot}
                  onChange={formik.handleChange}
                  aria-describedby="plantsPerPot-invalid"
                  disabled={isTrialTracker}
                />
                {formik.errors.plantsPerPot ? (
                  <small
                    id="plantsPerPot-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.plantsPerPot}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3">
                Crop Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note ? 'p-invalid' : null}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                />
                {formik.errors.note ? (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3">
                Description
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.description ? 'p-invalid' : null}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                />
                {formik.errors.description ? (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
          <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
            <Button
              className={styles.button}
              label="Save"
              type="submit"
              disabled={Boolean(
                isSubmitted ||
                  !formik.values.plants ||
                  !formik.values.section ||
                  !formik.values.quantity ||
                  formik.errors.plants ||
                  formik.errors.section ||
                  formik.errors.plantDate ||
                  formik.errors.quantity ||
                  formik.errors.lotNumber ||
                  formik.errors.note
              )}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className="confirmDialog"
        header={refTableHeader()}
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
          trialId={savedTrialId}
        />
      </Dialog>
    </Fragment>
  );
};

export default NewCropForm;
