import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useAuth0 } from '@auth0/auth0-react';
import { useFormik } from 'formik';
import moment from 'moment/moment';

import { getReportOptions } from '../../../reduxStore/reportOptions/actions';
import { getExportURL } from '../../Common/utils';
import { isAuth } from '../../../auth/auth-service';
import {
  initialReportFormSchema,
  reportFormSchema,
} from './NewReportPDFFormValidation';
import { useRequest, urls } from '../../Common/ApiServices';
import { getExportFieldNames } from '../../Trials/utils';

import PromptIfDirty from '../../Common/PromptIfDirty';
import Spinner from '../../Common/Spinner/Spinner';

import styles from './NewReportPDFForm.module.scss';

const NewReportRdfForm = ({
  displayReportModal,
  setDisplayReportModal,
  filters,
  selectedColumns,
}) => {
  const { logout, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const trialId = localStorage.getItem('trialId');

  const getActionData = (trialId) => {
    return {
      logout: logout,
      dispatch: dispatch,
      isAuthenticated: isAuth(),
      getAccessTokenSilently: getAccessTokenSilently,
      trialId: trialId,
    };
  };

  const { isLoading, sendRequest } = useRequest({});

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Do you want to close the form?\nAll changes will be lost.',
        accept,
      });
    } else {
      accept();
    }
  };

  const formik = useFormik({
    initialValues: initialReportFormSchema(),
    validationSchema: reportFormSchema,
    onSubmit: async (values) => {
      const reportParams = {
        trial: {
          id: {
            is: trialId,
          },
        },
        crop: {
          plantDate: {
            from: moment(filters.plantDate?.from).format('YYYY-MM-DD'),
            to: moment(filters.plantDate?.to).format('YYYY-MM-DD'),
          },
        },
        log: {
          createDate: filters.log?.createDate,
        },
        include: {
          images: values.images,
          logsData: values.logsData,
          productApplication: values.productApplication,
          fieldNames: getExportFieldNames(selectedColumns),
        },
        section: filters.section ? filters.section : null,
        program: filters.program ? filters.program : null,
        genus: filters.genus ? filters.genus : null,
        series: filters.series ? filters.series : null,
      };

      const requestData = {
        url: urls.REPORT_GENERATE,
        method: 'POST',
        data: { query: reportParams },
        headers: [],
        responseType: 'blob',
      };

      const response = await sendRequest(requestData);
      if (response) {
        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];

        const dayToday = moment().format('YYYY-MM-DD_HH:mm:ss');
        const fileName =
          contentDisposition?.slice(contentDisposition.indexOf('=') + 1) ||
          `report_${dayToday}.pdf`;
        const link = document.createElement('a');
        link.href = getExportURL([response.data], { type: contentType });
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        setDisplayReportModal(false);
      }
      return response;
    },
  });

  const onHide = () => {
    setDisplayReportModal(false);
    formik.resetForm();
  };

  useEffect(() => {
    trialId && dispatch(getReportOptions(getActionData(trialId)));
  }, [trialId]);

  return (
    <Dialog
      className={styles.reportFormDialog}
      header="Report Information"
      visible={displayReportModal}
      onHide={() => confirm(formik.dirty)}
    >
      <Divider />
      {isLoading && <Spinner />}
      <PromptIfDirty dirty={formik.dirty} />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid">
          <div className="p-field p-grid p-ai-start">
            <div className={`${styles.checkbox} p-field-checkbox`}>
              <Checkbox
                id="images"
                onChange={formik.handleChange}
                checked={formik.values.images}
              />
              <label htmlFor="images">Include Images</label>
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <div className={`${styles.checkbox} p-field-checkbox`}>
              <Checkbox
                id="logsData"
                onChange={formik.handleChange}
                checked={formik.values.logsData}
              />
              <label htmlFor="dataLogs">Include Logs Data</label>
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <div className={`${styles.checkbox} p-field-checkbox`}>
              <Checkbox
                id="productApplication"
                onChange={formik.handleChange}
                checked={formik.values.productApplication}
              />
              <label htmlFor="productApplication">
                Include Product Application
              </label>
            </div>
          </div>
        </div>
        <div className="p-grid p-col-12 p-justify-end pad-r-0 margin-l-0">
          <Button
            className={styles.button}
            label="Generate"
            type="submit"
            icon="pi pi-check"
            autoFocus
          />
          <Button
            className={`p-button-secondary ${styles.button}`}
            label="Cancel"
            type="button"
            icon="pi pi-times"
            onClick={() => confirm(formik.dirty)}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default NewReportRdfForm;
