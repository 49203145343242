import * as actionTypes from './actions';

const initialState = {
  sections: null,
  programs: null,
  genuses: null,
  series: null,
  colors: null,
  breeders: null,
  marketStatuses: null,
  hardinessZones: null,
  trialFiltersReady: false,
};

export const trialFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRIAL_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.TRIAL_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sections: action.sections,
        programs: action.programs,
        genuses: action.genuses,
        series: action.series,
        colors: action.colors,
        breeders: action.breeders,
        marketStatuses: action.marketStatuses,
        hardinessZones: action.hardinessZones.data.hardinessZones,
        trialFiltersReady: true,
      };
    case actionTypes.TRIAL_FILTERS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
