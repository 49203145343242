import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { MultiSelect } from 'primereact/multiselect';
import DatePicker from 'datepicker-special-week-numbers';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import styles from './DynamicField.module.scss';

const DynamicField = ({
  trialField,
  formik,
  createLogImage,
  deleteLogImage,
  updateLogImage,
}) => {
  const [isImageUpdating, setIsImageUpdating] = useState(true);

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const fileUploadRef = useRef(null);
  const fileUpdateRef = useRef(null);

  const onImageUpdate = (file, imageId) => {
    updateLogImage(file, imageId);
    setIsImageUpdating(true);
  };

  const headerTemplate = ({ className, chooseButton, cancelButton }) => {
    const customCancelButton = React.cloneElement(cancelButton, {
      onClick: (event) => {
        if (cancelButton.props.onClick) {
          cancelButton.props.onClick(event);
        }

        formik.setFieldValue(trialField.name, {});
        trialField.eventType === 'PROGRESS'
          ? deleteLogImage(trialField.id)
          : deleteLogImage(formik.values[trialField.name].id);
      },
      disabled: isEmpty(formik.values[trialField.name]),
    });

    const progressBar = (
      <div
        className="p-d-flex p-jc-end"
        style={{ position: 'absolute', right: '35px', top: '50%' }}
      >
        <ProgressBar
          className="p-ml-5 p-as-center"
          mode="indeterminate"
          style={{ width: '250px', height: '7px', position: 'relative' }}
        />
      </div>
    );

    return (
      <div
        className={`${className} p-jc-between`}
        style={{
          backgroundColor: 'transparent',
          position: 'relative',
        }}
      >
        {chooseButton}
        {customCancelButton}
        {isImageUpdating && progressBar}
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  };

  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  const chooseUpdateOptions = {
    icon: 'pi pi-fw pi-images',
    className: classNames(
      'p-button-outlined p-button-rounded',
      isImageUpdating && styles.activeUpdateImage
    ),
    label: 'Update',
  };

  const imageTemplate = (file) => {
    return (
      <div
        className="image-template p-d-flex p-ai-center p-flex-wrap"
        style={{ position: 'relative' }}
      >
        <div className={classNames('p-d-flex p-ai-center', styles.template)}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={180}
          />
        </div>
        <div className={styles.updateImage}>
          <FileUpload
            className={styles.updateImage}
            mode="basic"
            ref={fileUpdateRef}
            chooseOptions={chooseUpdateOptions}
            disabled={isImageUpdating}
            accept=".jpeg,.jpg,.png"
            maxFileSize={1000000}
            uploadHandler={(event) =>
              onImageUpdate(event.files[0], formik.values[trialField.name].id)
            }
            customUpload={true}
          />
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    if (!!formik.values[trialField.name]?.id) {
      return (
        <div className="empty-template1 p-d-flex p-ai-center p-flex-wrap">
          <div className={classNames('p-d-flex p-ai-center', styles.template)}>
            <img
              className={styles.templateImage}
              role="presentation"
              src={formik.values[trialField.name].thumbnail}
              width={250}
            />
          </div>
          <div className={styles.updateImage}>
            <FileUpload
              className={styles.updateImage}
              mode="basic"
              ref={fileUpdateRef}
              chooseOptions={chooseUpdateOptions}
              disabled={isImageUpdating}
              accept=".jpeg,.jpg,.png"
              maxFileSize={1000000}
              uploadHandler={(event) =>
                onImageUpdate(event.files[0], formik.values[trialField.name].id)
              }
              customUpload={true}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="empty-template2 p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-image p-mt-1 p-p-3"
          style={{
            fontSize: '3em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        />
        <span
          style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }}
          className="p-my-2"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const CustomCalendarInput = forwardRef(
    ({ icon, icon2 = null, value, onClick, error, touch }, ref) => (
      <Button
        label={value}
        className={classNames(
          error && formik.touched[touch]
            ? styles.calendarButtonError
            : styles.calendarButton,
          'p-button-text p-button-plain'
        )}
        onClick={onClick}
        onBlur={() => {
          formik.handleBlur({ target: { name: touch } });
        }}
        ref={ref}
        type="button"
      >
        <i className={`pi ${icon}`} />
        {icon2 && <i className={`pi ${icon2}`} style={{ marginLeft: '5px' }} />}
      </Button>
    )
  );

  const onImageSelect = async (image, fieldName) => {
    const res = await createLogImage(image, fieldName);
    if (res) {
      formik.setFieldValue(trialField.name, {
        id: res.id,
        thumbnail: image.objectURL,
        full: '',
      });
    }
  };

  const renderInputField = (fieldType) => {
    switch (fieldType) {
      case 'TEXT':
        return (
          <InputText
            id={trialField.name}
            type="text"
            className={classNames(
              formik.errors[trialField.name] && 'p-invalid'
            )}
            onChange={formik.handleChange}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      case 'NOTE':
        return (
          <InputTextarea
            id={trialField.name}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            type="text"
            rows="4"
            onChange={formik.handleChange}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      case 'INTEGER':
        return (
          <InputNumber
            id={trialField.name}
            min={trialField.minValue ? trialField.minValue : 0}
            max={trialField.maxValue ? trialField.maxValue : 100000}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onChange={(e) => formik.setFieldValue(trialField.name, e.value)}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      case 'NUMBER':
        return (
          <InputNumber
            id={trialField.name}
            min={trialField.minValue ? trialField.minValue : 0}
            max={trialField.maxValue ? trialField.maxValue : 100000}
            minFractionDigits={2}
            maxFractionDigits={5}
            aria-describedby={`${trialField.name}-invalid`}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onChange={(e) => formik.setFieldValue(trialField.name, e.value)}
            value={formik.values[trialField.name]}
          />
        );
      case 'DATE':
        return (
          <DatePicker
            id={trialField.name}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik.values[trialField.name]}
            weekLabel={'Wk'}
            className={styles.datePicker}
            showWeekNumbers
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            customInput={
              <CustomCalendarInput
                icon="pi pi-calendar"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'TIME':
        return (
          <DatePicker
            id={trialField.name}
            className={classNames(
              styles.calendarButton,
              isTrialTracker && styles.disabledStyle
            )}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik?.values[trialField.name]}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            aria-describedby={trialField.name}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            dateFormat="h:mm a"
            timeFormat="h:mm a"
            customInput={
              <CustomCalendarInput
                icon="pi pi-clock"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'DATE_TIME':
        return (
          <DatePicker
            id={trialField.name}
            onChange={(val) => {
              formik.setFieldValue(trialField.name, val);
            }}
            selected={formik.values[trialField.name]}
            aria-describedby={trialField.name}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
            customInput={
              <CustomCalendarInput
                icon="pi pi-calendar"
                icon2="pi pi-clock"
                error={formik?.errors[trialField.name]}
                touch={trialField.name}
              />
            }
          />
        );
      case 'CHECKBOX':
        return (
          <Checkbox
            id={trialField.name}
            onChange={formik.handleChange}
            value={formik.values[trialField.name]}
            checked={formik.values[trialField.name]}
          />
        );
      case 'SELECT': {
        return trialField.multiselect ? (
          <MultiSelect
            id={trialField.name}
            optionLabel="name"
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            options={trialField.values || []}
            onChange={formik.handleChange}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        ) : (
          <Dropdown
            id={trialField.name}
            optionLabel="name"
            options={trialField.values || []}
            onChange={formik.handleChange}
            className={classNames(
              formik.touched[trialField.name] &&
                formik.errors[trialField.name] &&
                'p-invalid'
            )}
            onBlur={() => {
              formik.handleBlur({ target: { name: trialField.name } });
            }}
            value={formik.values[trialField.name]}
          />
        );
      }
      case 'IMAGE':
        return (
          <FileUpload
            ref={fileUploadRef}
            name={trialField.name}
            url={trialField.value}
            accept=".jpeg,.jpg,.png"
            maxFileSize={1000000}
            multiple={trialField.imageCount > 1}
            onSelect={(e) => {
              onImageSelect(e.files[0], trialField.name);
            }}
            onRemove={() => formik.setFieldValue(trialField.name, null)}
            headerTemplate={headerTemplate}
            itemTemplate={imageTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    fileUpdateRef?.current?.clear();
    setIsImageUpdating(false);
  }, [trialField]);

  return (
    <>
      {
        <div
          className={classNames(
            'p-field p-grid p-ai-start',
            styles.dynamicField
          )}
        >
          <label
            htmlFor="plant"
            className={classNames(
              'p-col-12 p-md-3',
              trialField.required && 'p - text - bold'
            )}
          >
            {trialField.required ? `${trialField.label}*` : trialField.label}
          </label>
          <div className={classNames('p-col-12 p-md-9', styles.inputZone)}>
            {renderInputField(trialField.type)}
            {formik.touched[trialField.name] && formik.errors[trialField.name] && (
              <small
                id={`${trialField.name}-invalid`}
                className="p-error p-d-block"
              >
                {formik.errors[trialField.name]}
              </small>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default DynamicField;
