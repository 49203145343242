import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';

import { initialPlantInfo } from '../constants';
import { getMappedPlantResponse } from '../utils';
import { urls, useRequest } from '../../Common/ApiServices';

import styles from './PlantReadOnlyInfoModal.module.scss';

const PlantReadOnlyInfoModal = ({ selectedPlantId, setSelectedPlant }) => {
  const [plantInfo, setPlantInfo] = useState(initialPlantInfo);

  const { sendRequest } = useRequest({});

  const extractPlantInfo = useCallback(async () => {
    const requestData = {
      url: urls.EXTRACT_PLANT,
      method: 'POST',
      data: { query: { plant: { id: { is: selectedPlantId } } } },
    };

    const response = await sendRequest(requestData);
    if (response) {
      setPlantInfo(getMappedPlantResponse(response.data));
    }

    return response;
  }, [selectedPlantId, sendRequest]);

  const onHide = () => {
    setSelectedPlant(null);
  };

  useEffect(() => {
    extractPlantInfo();
  }, [extractPlantInfo]);

  return (
    <>
      <Dialog
        className={styles.plantFModal}
        header="Plant Information"
        visible={selectedPlantId}
        onHide={() => onHide()}
      >
        <Divider />

        <div className="p-fluid">
          <div className="p-field p-grid p-ai-start">
            <label
              htmlFor="name"
              className="p-col-12 p-md-3 p-text-bold p-pr-0"
            >
              Name*
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="name"
                className={styles.readOnly}
                type="text"
                value={plantInfo.name}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label
              htmlFor="code"
              className="p-col-12 p-md-3 p-text-bold p-pr-0"
            >
              Code*
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="code"
                type="text"
                className={styles.readOnly}
                value={plantInfo.code}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="description" className="p-col-12 p-md-3 p-pr-0">
              Description
            </label>
            <div className="p-col-12 p-md-9">
              <InputTextarea
                id="description"
                type="text"
                className={styles.readOnly}
                rows="4"
                value={plantInfo.description}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="breeder" className="p-col-12 p-md-3 p-pr-0">
              Breeder
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="breeder"
                className={styles.readOnly}
                value={plantInfo.breeder?.name}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="program" className="p-col-12 p-md-3 p-pr-0">
              Program
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="program"
                className={styles.readOnly}
                value={plantInfo.program?.name}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="genus" className="p-col-12 p-md-3 p-pr-0">
              Genus
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="genus"
                className={styles.readOnly}
                value={plantInfo.genus?.name}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="series" className="p-col-12 p-md-3 p-pr-0">
              Series
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="series"
                className={styles.readOnly}
                value={plantInfo.series?.name}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="series" className="p-col-12 p-md-3 p-pr-0">
              Color
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="color"
                className={styles.readOnly}
                value={plantInfo.color?.name}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="marketStatus" className="p-col-12 p-md-3 p-pr-0">
              Market Status
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="marketStatus"
                className={styles.readOnly}
                value={plantInfo.marketStatus?.name}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="hardinessZone" className="p-col-12 p-md-3 p-pr-0">
              Min Hardiness Zone
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="hardinessZone"
                className={styles.readOnly}
                value={plantInfo.hardinessZone?.zone}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="active" className="p-col-12 p-md-3 p-pr-0">
              Active
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="active"
                className={styles.readOnly}
                value={plantInfo.active ? 'Yes' : 'No'}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="patentNumber" className="p-col-12 p-md-3 p-pr-0">
              Patent Number
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="patentNumber"
                type="text"
                className={styles.readOnly}
                value={plantInfo.patentNumber}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="tradeName" className="p-col-12 p-md-3 p-pr-0">
              Trade Name
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="tradeName"
                type="text"
                className={styles.readOnly}
                value={plantInfo.tradeName}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="scientificName" className="p-col-12 p-md-3 p-pr-0">
              Scientific Name
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="scientificName"
                type="text"
                className={styles.readOnly}
                value={plantInfo.scientificName}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="species" className="p-col-12 p-md-3 p-pr-0">
              Species
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="species"
                type="text"
                className={styles.readOnly}
                value={plantInfo.species}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="origin" className="p-col-12 p-md-3 p-pr-0">
              Origin
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="origin"
                type="text"
                className={styles.readOnly}
                value={plantInfo.origin}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="variety" className="p-col-12 p-md-3 p-pr-0">
              Variety
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="variety"
                type="text"
                className={styles.readOnly}
                value={plantInfo.variety}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="stockRequired" className="p-col-12 p-md-3 p-pr-0">
              Stock Required
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="stockRequired"
                type="text"
                className={styles.readOnly}
                value={plantInfo.stockRequired}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="yieldEstimation" className="p-col-12 p-md-3 p-pr-0">
              Yield Estimation
            </label>
            <div className="p-col-12 p-md-9">
              <InputText
                id="yieldEstimation"
                type="text"
                className={styles.readOnly}
                value={plantInfo.yieldEstimation}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="note" className="p-col-12 p-md-3 p-pr-0">
              Note
            </label>
            <div className="p-col-12 p-md-9">
              <InputTextarea
                id="note"
                type="text"
                className={styles.readOnly}
                rows="4"
                value={plantInfo.note}
                readOnly
              />
            </div>
          </div>
          <div className="p-field p-grid p-ai-start">
            <label htmlFor="note" className="p-col-12 p-md-3 p-pr-0">
              PDF File
            </label>
            <div className="p-col-12 p-md-9">
              <div className={styles.pdfLinkWrapper}>
                {plantInfo.pdfFile && (
                  <Link
                    to={{ pathname: plantInfo.pdfFile?.url }}
                    target="_blank"
                  >
                    {plantInfo.pdfFile?.name}
                  </Link>
                )}
              </div>
            </div>
          </div>
          {plantInfo.image?.full && (
            <div className="p-field p-grid p-ai-start">
              <div className={classNames('p-col-12 p-md-12')}>
                <img
                  src={plantInfo.image?.full}
                  alt="plant image"
                  className={styles.plantImage}
                />
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default PlantReadOnlyInfoModal;
