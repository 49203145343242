import moment from 'moment/moment';

export const initialBatchCropInfo = {
  trialId: '',
  section: '',
  startForm: '',
  potSize: '',
  startDate: null,
  plantDate: null,
  transplantDate: null,
  spaceDate1: null,
  spaceDate2: null,
  quantity: '',
  plantsPerPot: '',
  note: '',
};

export const modals = {
  CROP: 'CROP',
  PRINT: 'PRINT',
  BATCH: 'BATCH',
  ADD_NEW: 'ADD_NEW',
  EVENT_LOG: 'EVENT_LOG',
  PROGRESS_LOG: 'PROGRESS_LOG',
};

export const getCreateLogRequestData = (id, eventType) => {
  return {
    parent: {
      crop: {
        id: id,
      },
    },
    log: {
      createDate: moment().toISOString(),
      fields: [],
      eventType: eventType,
    },
  };
};

export const getFetchLogsData = (id, eventType) => {
  return {
    query: {
      crop: { id: { is: id } },
      log: {
        eventType: eventType,
      },
    },
    navigation: {
      sort: [
        { key: 'logWeek', order: 'DESC' },
        { key: 'logDate', order: 'DESC' },
      ],
      page: { from: 0, size: 20 },
    },
  };
};
