import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { urls, useRequest } from '../../../Common/ApiServices';
import ReferenceTable from '../../../Common/ReferenceTable/ReferenceTable';
import { initialBatchFormSchema, batchFormSchema } from './batchFormValidation';
import { activeOptions, initialBatchInfo } from '../constants';
import styles from './BatchForm.module.scss';

const BatchForm = ({
  displayBatchModal,
  setDisplayBatchModal,
  selectedPlants,
  setSelectedPlants,
  programs,
  genuses,
  series,
  colors,
  breeders,
  marketStatuses,
  hardinessZones,
  setBatchIsUpdated,
}) => {
  const [currentRefTableName, setCurrentRefTableName] = useState('');
  const [displayRefTable, setDisplayRefTable] = useState(false);

  const { sendRequest } = useRequest({});

  const isTrialTracker = useSelector((state) => state.isTrialTracker);

  const showElements = useMemo(
    () => (isTrialTracker ? 'none' : 'inline-flex'),
    [isTrialTracker]
  );

  const refTableValues = {
    breeders: breeders,
    genuses: genuses,
    series: series,
    colors: colors,
    programs: programs,
    'market-statuses': marketStatuses,
  };

  const updateBatch = async (batchParams) => {
    const requestData = {
      url: urls.BATCH_PLANT_UPDATE,
      method: 'POST',
      data: {
        ids: selectedPlants.map((plant) => {
          return { id: plant.id, versionNumber: plant.versionNumber };
        }),
        ...(batchParams.description && {
          description: batchParams.description,
        }),
        ...(batchParams.tradeName && { tradeName: batchParams.tradeName }),
        ...(batchParams.scientificName && {
          scientificName: batchParams.scientificName,
        }),
        ...(batchParams.note && { note: batchParams.note }),
        ...(batchParams.instructions && {
          instructions: batchParams.instructions,
        }),
        ...(batchParams.breederId && { breederId: batchParams.breederId }),
        ...(batchParams.programId && { programId: batchParams.programId }),
        ...(batchParams.genusId && { genusId: batchParams.genusId }),
        ...(batchParams.seriesId && { seriesId: batchParams.seriesId }),
        ...(batchParams.marketStatusId && {
          marketStatusId: batchParams.marketStatusId,
        }),
        ...(batchParams.colorId && { colorId: batchParams.colorId }),
        ...(batchParams.species && { species: batchParams.species }),
        active: batchParams.active,
        ...(batchParams.hardinessZoneId && {
          hardinessZoneId: batchParams.hardinessZoneId,
        }),
      },
    };

    const response = await sendRequest(requestData);
    if (response) {
      setBatchIsUpdated(true);
      onHide();
    }
    return response;
  };

  const accept = () => {
    onHide();
  };

  const confirm = (dirty) => {
    if (dirty) {
      confirmDialog({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to discard the changes?',
        accept,
      });
    } else {
      accept();
    }
  };

  const onHide = () => {
    formik.resetForm();
    setDisplayBatchModal(false);
    setSelectedPlants([]);
  };

  const formik = useFormik({
    initialValues: initialBatchFormSchema(initialBatchInfo),
    enableReinitialize: true,
    validationSchema: batchFormSchema,
    onSubmit: (values) => {
      const batchParams = {
        description: values.description || null,
        breederId: values.breeder?.id || null,
        programId: values.program?.id || null,
        genusId: values.genus?.id || null,
        seriesId: values.series?.id || null,
        species: values.species,
        marketStatusId: values.marketStatus?.id || null,
        hardinessZoneId: values.hardinessZone?.id || null,
        colorId: values.color?.id || null,
        active: values.active.status,
        tradeName: values.tradeName || null,
        scientificName: values.scientificName || null,
        note: values.note || null,
      };
      updateBatch(batchParams);
    },
  });

  const onOpenRefTable = (value) => {
    setCurrentRefTableName(value);
    setDisplayRefTable(true);
  };

  const refTableHeader = () => {
    if (currentRefTableName === 'market-statuses') {
      return 'Market Status';
    }
    return (
      currentRefTableName[0]?.toUpperCase() + currentRefTableName?.slice(1)
    );
  };

  return (
    <>
      <Dialog
        className={styles.batchFormDialog}
        header="Update Information"
        visible={displayBatchModal}
        onHide={() => confirm(formik.dirty)}
      >
        <Tooltip
          target=".custom-choose-btn"
          content="Choose"
          position="bottom"
        />
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid">
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="description" className="p-col-12 p-md-3 p-pr-0">
                Description
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.description && 'p-invalid'}
                  id="description"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="description-invalid"
                  value={formik.values.description}
                  disabled={isTrialTracker}
                />
                {formik.errors.description && (
                  <small id="description-invalid" className="p-error p-d-block">
                    {formik.errors.description}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="breeder" className="p-col-12 p-md-3 p-pr-0">
                Breeder
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="breeder"
                  onChange={formik.handleChange}
                  value={formik.values.breeder}
                  options={breeders}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('breeders')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="program" className="p-col-12 p-md-3 p-pr-0">
                Program
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="program"
                  onChange={formik.handleChange}
                  value={formik.values.program}
                  options={programs}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('programs')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="genus" className="p-col-12 p-md-3 p-pr-0">
                Genus
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="genus"
                  onChange={formik.handleChange}
                  value={formik.values.genus}
                  options={genuses}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('genuses')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="series" className="p-col-12 p-md-3 p-pr-0">
                Series
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="series"
                  onChange={formik.handleChange}
                  value={formik.values.series}
                  options={series}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('series')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="series" className="p-col-12 p-md-3 p-pr-0">
                Color
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="color"
                  onChange={formik.handleChange}
                  value={formik.values.color}
                  options={colors}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('colors')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="marketStatus" className="p-col-12 p-md-3 p-pr-0">
                Market Status
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="marketStatus"
                  onChange={formik.handleChange}
                  value={formik.values.marketStatus}
                  options={marketStatuses}
                  optionLabel="name"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
              <div className="p-col-3 p-md-2">
                <Button
                  className="p-button-rounded p-button-info p-button-outlined"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ display: showElements }}
                  onClick={() => onOpenRefTable('market-statuses')}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="hardinessZone" className="p-col-12 p-md-3 p-pr-0">
                Min Hardiness Zone
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="hardinessZone"
                  onChange={formik.handleChange}
                  value={formik.values.hardinessZone}
                  options={hardinessZones}
                  optionLabel="zone"
                  filter
                  showClear
                  disabled={isTrialTracker}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="active" className="p-col-12 p-md-3 p-pr-0">
                Active
              </label>
              <div className="p-col-9 p-md-7">
                <Dropdown
                  id="active"
                  onChange={formik.handleChange}
                  optionLabel="label"
                  value={formik.values.active}
                  className="p-dropdown"
                  options={activeOptions}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="species" className="p-col-12 p-md-3 p-pr-0">
                Species
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="species"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.species}
                  aria-describedby="species-invalid"
                  className={formik.errors.species ? 'p-invalid' : null}
                  disabled={isTrialTracker}
                />
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="tradeName" className="p-col-12 p-md-3 p-pr-0">
                Trade Name
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="tradeName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tradeName}
                  aria-describedby="tradeName-invalid"
                  className={formik.errors.tradeName && 'p-invalid'}
                  disabled={isTrialTracker}
                />
                {formik.touched.tradeName && formik.errors.tradeName && (
                  <small id="tradeName-invalid" className="p-error p-d-block">
                    {formik.errors.tradeName}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label
                htmlFor="scientificName"
                className="p-col-12 p-md-3 p-pr-0"
              >
                Scientific Name
              </label>
              <div className="p-col-12 p-md-9">
                <InputText
                  id="scientificName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.scientificName}
                  aria-describedby="scientificName-invalid"
                  className={formik.errors.scientificName && 'p-invalid'}
                  disabled={isTrialTracker}
                />
                {formik.touched.scientificName && formik.errors.scientificName && (
                  <small
                    id="scientificName-invalid"
                    className="p-error p-d-block"
                  >
                    {formik.errors.scientificName}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field p-grid p-ai-start">
              <label htmlFor="note" className="p-col-12 p-md-3 p-pr-0">
                Note
              </label>
              <div className="p-col-12 p-md-9">
                <InputTextarea
                  className={formik.errors.note && 'p-invalid'}
                  id="note"
                  type="text"
                  rows="4"
                  onChange={formik.handleChange}
                  aria-describedby="note-invalid"
                  value={formik.values.note}
                  disabled={isTrialTracker}
                />
                {formik.errors.note && (
                  <small id="note-invalid" className="p-error p-d-block">
                    {formik.errors.note}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'p-grid p-col-12 p-justify-end margin-l-0',
              styles.noTopPadding,
              styles.noRightPadding
            )}
          >
            <Button
              className={styles.button}
              style={{ display: showElements }}
              label="Save"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
              icon="pi pi-check"
              autoFocus
            />
            <Button
              className={`p-button-secondary ${styles.button}`}
              style={{ display: showElements }}
              label="Cancel"
              type="button"
              icon="pi pi-times"
              onClick={() => confirm(formik.dirty)}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        className={'confirmDialog'}
        header={refTableHeader()}
        visible={displayRefTable}
        onHide={() => setDisplayRefTable(false)}
      >
        <ReferenceTable
          referenceBook={true}
          values={refTableValues[currentRefTableName]}
          margin="20px"
          referenceName={currentRefTableName}
          editable={true}
        />
        <div />
      </Dialog>
    </>
  );
};

export default BatchForm;
